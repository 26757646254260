@import "../../../sass/css-variables.scss";

.input{
    position: relative;

    &, * { box-sizing: border-box }

    label {
        background-color: white;
        left: 5px;
        position: absolute;
        pointer-events: none;
        top: -7px;
        display: flex;
        max-width: 95%;
        padding: 0 5px 0 5px;
        -webkit-transition: all 100ms ease-out;
        -moz-transition: all 100ms ease-out;
        -o-transition: all 100ms ease-out;
        transition: all 100ms ease-out;
        z-index: 1;

        span{
            font-family: Arial, sans-serif;
            font-size: 12px;
            font-weight: bold;

            &:nth-child(1){
                color:#d52b1e;
                padding-right: 3px;
            }

            &:nth-child(2){
                color:$regular-text-color;
                flex: 1;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &.placeholder {
            transform: translate3d(0, 18px, 0);

            span {
                &:nth-child(1) {
                    opacity: 0.7;
                }

                &:nth-child(2) {
                    font-size: 14px;
                    font-weight: 500;
                    opacity: 0.7;
                }
            }
        }
    }

    input, textarea {
        border: solid 1px #c4c4c4;
        padding-left: 12px;
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: $autozone-black-color;
        width: 100%;
        height: 38px;
        outline: none;
        -webkit-transition: all 100ms ease-out;
        -moz-transition: all 100ms ease-out;
        -o-transition: all 100ms ease-out;
        transition: all 100ms ease-out;

        &:focus,
        &:active {
            border-color: $autozone-black-color;
            box-shadow: 0 0 4px #0002;
        }

        &:invalid {
            box-shadow: none;
        }
    }

    textarea {
        padding-top: 12px;
        resize: none;
    }

    .textArea-container {
        position: relative;
        z-index: 0;

        textarea {
            position: relative;
            z-index: 0;
        }

        &:after, &:before {
            background-color: #fff;
            content: '';
            display: block;
            height: 5px;
            left: 1px;
            position: absolute;
            right: 18px;
            z-index: 1;
        }

        &:after {
            bottom: 5px;
        }

        &:before {
            top: 1px;
        }
    }

    .error-message {
        color: $autozone-red-color;
        display: block;
        font-family: sans-serif;
        font-size: 11px;
        padding-top: 6px;
        margin-bottom: -4px;
    }

    .info-message {
        color: $autozone-black-color;
        display: block;
        font-family: sans-serif;
        font-size: 11px;
        padding-top: 2px;
        margin-bottom: -4px;
    }

    .input-icon {
        left: 8px;
        position: absolute;
        top: 5px;
    }


    &.error {
        input, textarea {
            border-color: $autozone-red-color;
            padding-left: 20px;
        }

        label.placeholder span {
            &:nth-child(1) {
                opacity: 1;
            }

            &:nth-child(2) {
                opacity: 0.7;
            }
        }
    }

    .shell {
        position: relative;
        line-height: 1;

        span {
            position: absolute;
            left: 13px;
            top: 3px;
            color: #ccc;
            pointer-events: none;
            z-index: 0;

            i {
                font-style: normal;
                color: transparent;
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    input.masked,
    .shell span {
        font-size: 14px;
        font-family: Arial, sans-serif;
        padding-right: 10px;
        background-color: transparent;
        text-transform: uppercase;
    }

    &.with-icon {
        input {
            padding-left: 36px;
        }

        label {
            left: 34px;
        }

        .shell {
            span {
                left: 38px;
            }
        }
    }
}

.pac-container {
    border: 1px solid #c4c4c4;
    box-shadow: none;

    &:after {
        display: none;
    }

    &:before {
        display: block;
        color: #787878;
        content: "search suggestions";
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: 12px;
        padding: 0 0 16px 3px;
        text-transform: uppercase;
    }

    .pac-item {
        border-top: none;
        line-height: 32px;

        &, .pac-item-query, .pac-matched, > span {
            color: #202124;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 16px;
        }

        .pac-icon {
            display: none;
        }
    }
}
