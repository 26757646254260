@import "../../../sass/css-variables.scss";

.checkBox{
    align-items: center;
    display: flex;

    &, * {
        box-sizing: border-box;
    }

    &.big .check {
        flex-shrink: 0;
        height: 20px;
        width: 20px;
    }
    
    .check{
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    
    .check-active{
        background-color:$autozone-orange-color;
    }
    
    .check-inactive{
        background-color: #ffffff;
        border:solid 1px #c4c4c4
    }

    img {
        width: 10px;
    }

    .text{
        margin-left: 8px;
        font-family: Arial, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        line-height: 1em;
        color:$regular-text-color;
    }
}
