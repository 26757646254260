@import "../../../../../sass/css-variables";
@import "../../../../../sass/mixins";

$point-size: 12px;
$point-position: ($point-size / 2) * -1;

.ImageEditor {
    &, * { box-sizing: border-box }

    @include flex(column);

    background-color: #fff;
    border: solid 1px $gray-color;
    height: 600px;
    max-height: 100vh;
    max-width: 100%;
    width: 600px;

    .crop-area {
        left: 50%;
        max-width: 100%;
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .crop-container {
        height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        position: relative;

        /* Rewriting cropper plugin css rules */
        .cropper-line {
            background-color: transparent;
            height: 0;
        }

        .cropper-modal {
           opacity: 0.2;
        }

        .cropper-point {
            background-color: #393939;
            border: 1px solid white;
            height: $point-size;
            width: $point-size;

            &.point-n, &.point-e, &.point-s, &.point-w {
                visibility: hidden;
            }

            &.point-ne {
                right: $point-position;
                top: $point-position;
            }

            &.point-nw {
                left: $point-position;
                top: $point-position;
            }

            &.point-se {
                bottom: $point-position;
                right: $point-position;
            }

            &.point-sw {
                bottom: $point-position;
                left: $point-position;
            }
        }

        .cropper-view-box {
            outline: 1px dashed rgba(white, 0.7);
        }
    }

    .editor-image {
        min-height: 70px;
        min-width: 70px;
        height: 100%;
        width: 100%;
    }

    .editor-thumbnail {
        border: 1px solid $gray-color;
        flex-shrink: 0;
        height: 70px;
        margin-right: 16px;
        overflow: hidden;
        position: relative;
        width: 70px;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .modal-body {
        @include flex(column);

        flex: 1;
        overflow: hidden;
        padding: 16px;
    }

    .modal-content {
        @include flex(column);

        background-color: $lightgray-color;
        border: 1px solid #e9e9e9;
        flex: 1;
        -webkit-flex: 1;
        overflow: hidden;
        padding: 16px;

        hr {
            border: 1px solid transparent;
            border-top-color: $gray-color;
            margin: 16px 0;
        }

        p {
            color: $regular-text-color;
            font-family: Arial, sans-serif;
            font-size: 14px;
            line-height: 1.5em;
            margin: 0;
        }
    }

    .row {
        @include flex();

        align-items: flex-start;
        -webkit-align-items: flex-start;
    }
}