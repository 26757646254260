@import "../../../sass/css-variables.scss";
@import "../../../sass/global.scss";

.homePage {
       padding-bottom: 48px;

}

.description {
       p {
              line-height: 1.5;       
              color: $regular-text-color;       
              margin: 0 16px 24px 0;

              &:last-child {
                     margin-bottom: 0;
              }

              a, a:hover, a:visited, a:link, a:active {
                     color: $autozone-orange-color;
                     cursor: pointer;
                     text-decoration: underline;
              }
       }

       ul {
              margin-top: 0;
       }
       
       ul > li{
              list-style-image: url('../../../assets/triangle.png');
              line-height: 1.67;

              a, a:hover, a:visited, a:link, a:active {
                     color: $autozone-orange-color;
                     cursor: pointer;
                     text-decoration: underline;
              }
       }
       h2{
              line-height: 1.4;
       }
       button{
              margin-bottom: 24px;
       }
        
}
img.imagebox {
       display: block;
       width: 100%;
       height: 100%;
       max-width: 582px;
       max-height: 429px;
       object-fit: cover;   
       background-image: url('../../../assets/landing-page.jpg');
       background-size: 111%;
       background-repeat: no-repeat;   
}
@media screen and (max-width: 768px) {
       img.imagebox {
              display: block;
              width: 100%;
              height:100%;
              object-fit: cover;
              background-image: url('../../../assets/landing-page.jpg');
              //background-size: 122%;
              background-repeat: no-repeat;
       }
} 