.box{
    border: solid 1px #c4c4c4;
    box-sizing: border-box;

    .header{
        box-sizing: border-box;
        min-height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 16px 0px 16px;

        @media screen and (max-width: 576px) {
            & {
                max-height: 60px;
                padding: 12px 16px
            }
        }

        .title{
            font-family: 'AutoZoneCond-Medium', Arial, sans-serif;
            font-size: 16px;
        }

        .hint{
            font-family: Arial;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
        }
    }

    .content{
        padding: 16px 16px 1px 16px;
        background-color: #ffffff;
        border-top: none;
    }
}