.portal{
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(128,128,128,0.5);

    display: flex;
    justify-content: center;
    align-items: center;

    #portal-container {
        box-sizing: border-box;
        padding: 0 16px;
        max-width: 100%;
    }
}
