@import "../../../../sass/css-variables";

.CouponPreviewModal {
    &, * { box-sizing: border-box }

    background-color: #fff;
    box-shadow: 0 0 30px rgba(0,0,0,0.3);
    min-height: 417px;
    max-width: 100%;
    padding: 40px;
    position: relative;
    width: 542px;

    .close-button {
        position: absolute;
        right: 14px;
        top: 14px;
    }

    .coupon-wrapper {
        left: 40px;
        margin-top: 50px;
    }

    .cta {
        background-color: #202124;
        border: none;
        color: #fff;
        display: block;
        font-family: "AutoZoneCond-Medium", sans-serif;
        font-size: 16px;
        font-weight: normal;
        height: 50px;
        left: 50%;
        margin: 30px 0 0 0;
        max-width: 100%;
        padding: 0;
        position: relative;
        text-transform: uppercase;
        transform: translateX(-50%);
        width: 360px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 63px;
    }

    @media screen and (max-width: 567px) {
        box-shadow: none;
        height: calc(100% + 16px);
        margin: 0 0 -16px 0;
        padding: 16px;

        &:before {
            background-color: #202124;
            content: '';
            height: 36px;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        .close-button {
            top: 6px;
        }

        .cta {
            bottom: 0;
            height: 40px;
            left: 8px;
            margin-top: 40px;
            position: relative;
            transform: translateX(0);
            width: calc(100% - 16px);
        }

        .coupon-container {
            border: 1px solid #979797;
            min-height: 200px;
            margin-top: 36px;
            overflow-y: hidden;
            overflow-x: auto;
            position: relative;
        }

        .coupon-wrapper {
            left: 50%;
            margin-left: 0;
            margin-top: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);

            &.md {
                left: 0;
                top: 0;
                transform: translate(0, 0);
            }
        }
    }
}
