@import "../../../sass/css-variables.scss";

#boxStepOne{
    .address-card {
        display: flex;

        img {
            margin-bottom: 13px;
            margin-right: 13px;
        }
    }

    p{
        margin: 0;

        span{
            display: block;            
            font-family: Arial, sans-serif;
            font-size: 16px;
            font-weight: normal;
            color: $regular-text-color;                        
        }

        a, a:hover, a:visited, a:active{
            font-style: normal;
            display: block;
            color: $autozone-orange-color;
            text-decoration: none;
        }

        span.value{
            margin-bottom: 24px;
        }
    
        span.title{
            font-family: 'AutoZoneCond-Medium', sans-serif;
            font-size: 18px;       
            font-weight: normal;
            margin-bottom: 8px;
            color: $regular-text-color;
        }

        #pictureTitle{
            margin-bottom: 5px;
        }

        .email {
            overflow-wrap: break-word;
        }
            
    }
    p#address{
        line-height: 20px;

        span{
            margin: 0;

            &:nth-child(1){
                padding-bottom: 8px;
            }
        }            
    }

    img {
        object-fit: cover;
        height: 100px;
        width: 100px;
        display: block;
    }

    @media screen and (max-width: 768px) {
        .address-card .name {
            & { padding-bottom: 0 !important; }

            strong { letter-spacing: -0.2px }
        }
        .col {
            &:last-of-type { margin-bottom: 24px }
            &:not(:first-of-type) {
                .title, .value {
                    box-sizing: border-box;
                    display: inline-block;
                    line-height: 18px;
                    margin: 0;
                    vertical-align: top;
                    width: 50%;
                }

                .title {
                    margin-top: 16px;
                    padding-right: 4px;
                }

                .value {
                    margin-top: 18px;
                    padding-left: 8px;
                }
            }
        }
    }
}

.informationButtonContainer{
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    border: solid 1px #c4c4c4;
    background-color: #f8f8f8;
    border-top: none;    
    text-align: right;
    vertical-align: middle;
    padding: 16px;

    button {
        &#edit-profile { width: 100% }
        &#profile-cancel, &#profile-save { width: calc(50% - 8px) }
        &#profile-cancel { margin-right: 8px }
        &#profile-save { margin-left: 8px }
    }

    @media screen and (max-width: 320px) {
        & {
            height: auto;
        }

        button {
            margin: 0 !important;
            width: 100% !important;

            &#profile-cancel {
                margin-bottom: 16px !important;
            }
        }
    }
}

.detailsBox{
    border: solid 1px #c4c4c4;
    border-top: none;
    box-sizing: border-box;
    padding: 24px 16px 0 16px;

    fieldset {
        margin: 0;
        margin-bottom: 24px;
        margin-top: 0px;    
        border: solid 1px #c4c4c4;
        border-left: none;
        border-right: none;
        border-bottom: none;
        padding: 0;

        legend{
            font-size: 16px !important;
            color: $regular-text-color;            
            padding-right: 5px;
        }

        @media screen and (max-width: 768px) {
            & {
                margin-bottom: 16px;
            }

            legend { margin-bottom: 16px }
        }
             
    }
    hr{
        margin: 0 0 0 5px;
        display: inline-block;
        border: solid 1px #c4c4c4;        
        text-align: right;
        vertical-align: middle;
    }
    
    .timeHeader{
        height: 77px;
        border: solid 1px #c4c4c4;
        background-color: #f8f8f8;
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
    }
    
}