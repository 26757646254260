.error-container{
    display: flex;
    flex-direction: row;
    border: solid 1px #d52b1e;
    background-color: #fff2f0;
    padding: 10px 20px;
    margin:  0 0 16px 0;

    span.icon-error{
        background-image: url('../../../assets/error.svg');
        background-position: left center;
        background-repeat: no-repeat;
                
        box-sizing: border-box;
        position: relative;
        width: 16px;
        height: 16px;                
    }
    span.error-text{
        font-family: Arial;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #231f20;
        display: block;
        margin-left: 8px;
    }
}
@media screen and (max-width: 576px) {
    .error-container {
        margin: 0 0 16px;
        span.icon-error{
            width:45px;       
        }
    }
}