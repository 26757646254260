#shop-details{
    &, * { box-sizing: border-box }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        @media screen and (max-width: 768px) {
            &.services {
                margin-bottom: 24px;
            }
        }

        @media screen and (max-width: 567px) {
            &.shop-actions {
                li {
                    &:not(:last-of-type) {
                        margin-right: 32px;
                    }
                    &:nth-child(n + 3) {
                        margin-top: 16px;
                    }
                }
            }
        }

        > li {
            list-style-type: none;
            display: inline-block;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;                    
            margin-right: 64px;

            @media screen and (max-width: 768px) {
                &:not(:last-of-type) {
                    margin-right: 22px;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }


            a, a:hover, a:visited, a:active{
                color: #5b5c5d;
                text-decoration: underline;
            }

            > :last-child {
                margin-bottom: 0;
            }
        }
    }
    .map-image{
        background: url('../../../assets/map-shopdetails.JPG') left top no-repeat;
        object-fit: cover;
        background-size: cover;
        width: 100%;
        max-width: 1100px;
        height: 399px;
        display: block;
    }
    h1.hdr-title{
        display: flex;
        margin: 0;
        span.title{
            font-family: 'AutoZoneCond', sans-serif;
            font-size: 41.5px;
            font-weight: normal;
            font-style: normal;
            font-stretch: condensed;
            line-height: normal;
            letter-spacing: normal;
            color: #202124;
        }
        span.print-logo {
            display: none;
            text-align: right;
        }
        @media screen and (max-width: 567px) {
            line-height: 0.9;
        }
    }
    .print-header{
        display: none;
    }
    .details{
        margin: 40px 0 0;

        &.left {
            padding-right: 8px;
        }

        &.right {
            padding-left: 8px;
        }

        #address{
            margin: 0 ;
            display: block;

            span{
                display: block;                                                         
            }               
            
            span.title{         
                font-family: 'AutoZoneCond', sans-serif;            
                font-weight: normal;
                font-stretch: condensed;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #202124;   
                font-size: 28px;                                    
                text-transform: uppercase;   
                margin-bottom: 4px;
            }

            span.address, span.hours{
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #202124;
                margin-bottom: 6px;
            }

            span.hours{                
                font-weight: 600;
                line-height: 1.25;
                letter-spacing: normal;
                color: #4e8033;
                margin-bottom: 8px;
            }            

            span.phnumber{
                text-decoration: underline;
                color: #5b5c5d;
            }  
        }

        p#tools{ 
            margin-top: 10px;
            text-align: left;
        }

        .shop-image{
            border: 1px solid #e7e7e7;
            box-sizing: border-box;
            display: block;
            height: 200px;
            line-height: 0;
            object-fit: contain;
            width: 100%;
        }

        @media screen and (max-width: 768px) {
            #address {
                span.address {
                    line-height: 1.4;
                    padding-bottom: 10px;
                }

                span.hours {
                    line-height: 1;
                    padding-bottom: 8px;
                }

                span.title {
                    font-size: 24px;
                    padding-bottom: 10px;
                }
            }

            .shop-image {

            }
        }

        @media screen and (max-width: 567px) {
            &.left {
                margin-top: 36px;
            }

            &.right {
                margin-top: 24px;
                padding-left: 0;
            }

            #address {
                span.address {
                    padding-bottom: 4px;
                }

                span.title {
                    padding-bottom: 4px;
                }
            }

            p#tools {
                margin-top: 24px;
            }
        }
    }

    h3.backtosearch{        
        margin: 15px 0 0;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: 0.75px;
        display: flex;
        border-bottom: 1px solid #ddd;
        padding-bottom: 12px;

        a.back-arrow, a.back-arrow:hover, a.back-arrow:visited, a.back-arrow:active {
            cursor: pointer;
            background: url(../../../assets/left-arrow.JPG) left -1px no-repeat;
            color: #202124;
            font-family: 'AutoZoneCond-Medium', sans-serif;
            font-size: 17px;
            display: block;
            padding-left: 28px;
            height: 21px;
        }
    }

    span.title, p#info span.title{
        display: block;
        font-family: 'AutoZoneCond-Medium', sans-serif;
        font-size: 20px;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #202124;
        margin-bottom: 11px;
        text-transform: uppercase;

        strong{
            font-size: 18px;
            font-weight: normal;
        }
    }
    
    span.title{
        margin-bottom: 8px;
    }
    
    .shop-info{
        background-color: #f5f5f5;
        border-bottom: 1px solid #e1e1e1;
        border-top: 1px solid #e1e1e1;
        padding: 35px 16px 21px 16px;
        margin-top: 40px;

        p#info {
            margin: 0;
            padding: 10px 16px;
            display: block;
            width: 356px;
            height: 270px;
            border-radius: 3px;
            box-shadow: 0 3px 1px 0 rgba(170, 170, 170, 0.5);
            border: solid 1px #efefef;
            background-color: #ffffff;
             &, *{box-sizing: border-box;}
             
            .table{
                display: table;
                width:100%;
                border-collapse:collapse;
                position: relative;
                margin-bottom: 0;
            }
            .table-row{
                display: table-row;   
                height: 35px;             ;
            }
            .table-cell{
                display: table-cell;                  
                padding: 8px 16px;                
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 14px;
                font-weight: normal;      
            }
            .table-cell:nth-of-type(odd){
                width: 50%;
            }
            .table-cell:nth-of-type(even){
                width: 50%;
            }

            .table-head{            
                // height: 32px;
                background-color: #e5e5e5;                     

                > span{
                    font-weight:bold;               
                }
                
            }

            @media screen and (max-width: 768px) {
                margin-right: 32px;
                margin-bottom: 24px;
                width: auto;

                .table-cell:nth-of-type(odd){
                    width: 40%;
                }
                .table-cell:nth-of-type(even){
                    width: 60%;
                }
            }

            @media screen and (max-width: 567px) {
                margin-right: 0;
                padding: 10px 8px;

                .table-cell {
                    display: block;
                    padding: 7px 6px;
                }

                .table-row {
                    align-items: center;
                    display: flex;
                    height: 32px;
                    margin: 3.2px 0;
                }

                .table-head {
                    margin: 3.2px -8px;
                    padding: 7px 8px;
                }
            }
        }

        ul.services{
            margin-bottom: 20px;

            li {
                color: #484848;
                font-size: 14px;
                line-height: 26px;
                display: block;   
                margin-bottom: 10px;
            }
            
        }

        @media screen and (max-width: 567px) {
            padding: 10px;

            ul.services li { margin-bottom: 6px }
        }

    }

    .nearby{
        padding: 35px 0 42px;

        .title { margin-bottom: 8px }

        .nearby-list {
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
        }

        .nearby-store{
            padding: 8px 15px 0 0;
            display: inline-block;
            flex: 1;

            span, a {
                display: block;
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-weight: normal;            
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #202124;                
            }
            span.store-title {
                cursor: pointer;
                font-size: 18px;
                font-weight: normal;
                margin-bottom: 11px;
            }
            span.store-address{
                font-size: 16px;
                font-weight: normal;
                line-height: 1.5;
                margin-bottom: 11px;
            }
            a.store-number{
                text-decoration: underline;
                margin-bottom: 14px;
            }
            span.miles{                
                font-size: 14px;
                font-weight: bold;
                display: flex;
                    &:before{
                    object-fit: contain;            
                    content: url('../../../assets/mapsmall.svg');
                    margin-right: 5px;                
                }
            }

            @media screen and (max-width: 768px) {
                margin-bottom: 24px;
                margin-right: 0;
                width: 50%;
            }

            @media screen and (max-width: 576px) {
                min-width: 100%;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        @media screen and (max-width: 567px) {
            border-bottom: 1px solid #e1e1e1;
        }
    }

    .active-coupons {
        max-width: 100%;
        width: 350px;

        .col {
            margin-bottom: 8px;
        }

        .coupon-btn {
            border: none;
            line-height: 1;
            margin: 0;
            padding: 0;
            -webkit-appearance: none;
        }

        @media screen and (max-width: 768px) {
            margin: 0;
            width: 320px;
        }
    }
}

@media print{
    .findshop#shop-details{

        & *{box-sizing: border-box;}
        h1.hdr-title{
            display: flex;
            margin: 0;
            padding: 0;
            //border: 1px solid ;
            span.title{
                display: block;
                font-family: Arial, sans-serif;
                font-size: 20px;
                font-weight: 800;
                font-stretch: condensed;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000000;
                flex: 1;
                padding: 0;

                &.es {
                    font-size: 18px;
                }
            }
            span.print-logo{
                display: block;
                flex: 0;
                padding: 0;
                img{
                    display: block;
                    margin-top: 6px;
                }
            }

        }
        h3.backtosearch {
            margin: 0 0 12px;
             a.back-arrow{
                display: none;
            }
        }

        .details{
            margin: 0;
            padding: 8px;
            border: 1px solid #9b9b9b;
            width: 280px;
            min-height: 126px;

            img{
                display: block;
                width: 126px;
                height: 126px;
                margin: 0 auto;
            }
            span.print-header{
                font-family: Arial, sans-serif;
                font-size: 10px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000000;
                border-bottom: 1px solid #9b9b9b;
                padding: 0 8px;
                width: 280px;
                height: 19px;
                margin: 0 -8px 8px;
                display: block;
                position: relative;
            }
            #address{
                margin: 0;
                display: block;

                span{
                    display: block;
                    font-family: Arial, sans-serif;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #000;
                }

                span.title{
                    font-weight: bold;
                    font-size: 12px;
                    text-transform: capitalize;
                    margin-bottom: 0;
                }
                .address-card{

                    span.address, span.hours{
                        font-size: 10px;
                        line-height: 12px;
                        color: #000;
                        margin-bottom: 0;
                    }

                    span.hours{
                        font-weight: bold;
                        line-height: 1.25;
                        letter-spacing: normal;
                        color: #4e8033;
                        margin: 16px 0;
                    }
                }

                span.phnumber{
                    text-decoration: underline;
                    color: #5b5c5d;
                }
                ul.shop-actions{
                    margin: 0;
                    padding: 0;

                    > li{
                        margin: 0;
                        padding: 0;
                        line-height: normal;
                        a{
                            font-size: 10px;
                            text-decoration: none;
                        }
                        &:first-child{
                            display: none;
                        }
                        &:nth-child(2){

                            &:after{
                                content: '|';
                                font-size: 10px;
                                color: black;
                                margin: 0 5px;
                            }
                        }
                    }
                }
                p#tools{
                    display: none;
                }
            }
        }

        .shop-info{
            background-color: transparent;
            border: 1px solid #dadada;
            border-bottom: none;
            padding: 12px 0 0;
            margin-top: 12px;

            div{
                flex: 0;
                flex-direction: column;
                box-sizing: border-box;

                &:nth-child(1), &:nth-child(2), &:nth-child(3){
                    flex-basis: 100% !important;
                    max-width: 100% !important;
                    flex-wrap: nowrap;
                    padding: 0;

                    display: flex;
                    align-items: flex-start;

                }
                &:nth-child(1){
                    flex-direction: row;
                    div{
                        padding: 0 0 0 12px;
                        border-bottom: 1px solid #dadada;
                    }
                }
                &:nth-child(2), &:nth-child(3){
                    display: flex;
                    flex: 0;
                    //flex-direction: column;
                    align-items: flex-start;

                    div{
                        word-break: break-all;
                        display: flex;
                        //flex: 0 0 100%;
                        width: 100%;
                        height: auto;
                        flex-direction: row;
                        align-items: flex-start;
                        border-bottom: 1px solid #dadada;
                        padding: 12px;

                        span.title{
                            margin-top: 5px;
                        }
                    }
                }
                &:nth-child(3){
                    div:nth-child(3){
                        border-bottom: none;
                    }

                }
                span.title, span.title strong{
                    font-family: Arial, sans-serif;
                    font-size: 10px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #000000;
                    flex: 0 0 25%;
                    word-break: break-word;
                }

                p#info {
                    margin: 0 0 0 25px;
                    padding: 0;
                    box-shadow: none;
                    border: none;
                    background-color: transparent;
                    flex: 1 0 65%;
                    height: 100px;

                    .table{
                        display: table;
                        width:200px;

                        border-collapse:collapse;
                        position: relative;
                        margin-bottom: 0;
                    }
                    .table-row{
                        display: table-row;
                        height: 14px;
                    }
                    .table-cell{
                        display: table-cell;
                        padding: 0;
                        font-family: Arial, sans-serif;
                        font-size: 10px;
                        font-weight: normal;
                    }
                    .table-cell:nth-of-type(odd){
                        width: 50%;
                    }
                    .table-cell:nth-of-type(even){
                        width: 50%;
                        text-transform: lowercase;
                    }

                    .table-head{
                        background-color: transparent;
                    }

                }

                ul.services{
                    margin: 0 0 0 25px;
                    list-style: 0;
                    flex: 1 0 65%;
                    li {
                        list-style-type: none;
                        font-family: Arial;
                        font-size: 10px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #000000;
                        margin: 0 5px 0 0;
                        display: inline;
                    }

                }

                .active-coupons{
                    display: none;
                }
            }

        }
        .nearby{
            display: none;
        }
    }
}
