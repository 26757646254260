@import "../../../sass/css-variables.scss";

.stepWrapper{

    margin-top: -2px;
    padding-bottom: 48px;

    .footer-bar {
        margin: 16px 0 0 0;

        button {
            box-sizing: border-box;
            margin-left: 16px;
        }
    }

    @media screen and (max-width: 768px) {
        .footer-bar {
            margin: 0;

            button {
                margin-left: 0;
                width: calc(50% - 6px);

                &:first-of-type {
                    margin-right: 12px;
                }
            }
        }
    }
}