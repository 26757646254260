@import "./css-variables.scss";

body {
    background-color: #fff;
}

a, p, span, ul, li{
    font-family: Arial;
    font-size: 16px;  
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
}

h1{
    font-family: 'AutoZoneCond-Heavy', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    color: #231f20;
    display: inline-block;
    margin: 10px 0 8px 5px; 
}

h2, legend{
    font-family: 'AutoZoneCond-Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: condensed;
    line-height: 0.9;
    letter-spacing: normal;
    color: $regular-text-color;
    margin-bottom: 24px;
    margin-top: 0;
}

.big-title{
    font-family: Arial;
    font-size: 26px;        
    font-stretch: normal;
    margin: 0px 0px 24px 0px;
    color: $autozone-orange-color;
}
.faq-header, h4{
    font-family: AutoZoneCond;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;    
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.clickable {
    cursor: pointer;
}

.check-icon{    
    list-style-type: none;  
    padding: 0.25em 0 0 1.25em; 
    position: relative;        
    margin: 0;
    font-size: 24px;

    &::before {
        content: " "; // the circle itself is empty
        display: block;
        border: solid 0.45em #008300; // create a colored area...
        border-radius: .45em; // ... and turn it into a circle
        height: 0;
        width: 0;
        position: absolute; // the circle is then put into place
        left: 0em;
        top: 64%; 
        margin-top: -0.5em;
    }

    &::after {
        content: " ";
        display: block;
        width: 0.205em;
        height: 0.405em;
        border: solid white;
        border-width: 0 0.1em 0.1em 0;
        position: absolute;
        left: 0.275em;
        top: 64%;
        margin-top: -0.373em;
        transform: rotate(45deg);
    }
}
.lined-title {
    display: flex;
    align-items: center;

    &:after {
        background-color: #c4c4c4;
        content: '';
        display: inline-block;
        height: 1px;
        flex: 1;
        margin-left: 6px;
    }
}
