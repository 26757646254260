$autozone-orange-color: #f37f00;
$autozone-black-color: #3a3c3d;
$autozone-red-color: #d52b1e;
$lightgray-color: #f8f8f8;
$gray-color: #c4c4c4;
$regular-text-color: #231f20;

$breakpoint-lg: 1240px;
$breakpoint-md: 1023px;
$breakpoint-sm: 768px;

$stepper-items-space: 171px;
$stepper-items-circle-size: 36px;

$coupon-scale-mobile: scale(0.596);
$coupon-scale-mobile-sm: scale(0.529);
$coupon-scale-lg: scale(1);
$coupon-scale-md: scale(0.955);
$coupon-scale-sm: scale(0.537);
$coupon-scale-xs: scale(0.295);