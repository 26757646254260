.email-modal {
    &, * { box-sizing: border-box }

    background-color: #fff;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    width:542px;
    padding: 0 91px 40px;
    .content-header {       

        .modal-title {
            color: #202124;
            flex: 1;
            font-family: "AutoZoneCond", sans-serif;
            font-size: 30px;
            margin: 0;
            text-transform: uppercase;                     
        }
         
    }

    .modal-content {          
        margin-top: 8px;
        p{
            font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.2px;
            color: #202124;
            margin: 0 0 30px;
            display: block;
        }
        input[type="email"], input[type='text']{
            width: 356px;
            height: 56px;
            padding: 16px;
            border: solid 1px #a9aaa8;
            background-color: #ffffff;
            margin-bottom: 16px;
            font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #576a78;
        }
        input[type='text']{
            margin-bottom: 48px;
        }
        .modal-cta {
            background-color: #202124;
            border: none;
            color: #fff;
            font-family: "AutoZoneCond", sans-serif;
            font-weight: normal;
            font-size: 16px;
            height: 50px;
            letter-spacing: 0.75px;
            text-transform: uppercase;
            width: 356px;
        }
            
    }

    .modal-header {
        align-items: center;
        background-color: #fff;
        display: flex;
        height: 50px;
        justify-content: flex-end;
        padding: 0 16px;
    }  
}