@import "../../../sass/css-variables";
@import "../../../sass/mixins";

.ModalHeader{
    @include flex();

    align-items: center;
    background-color: #9b9b9b;
    height: 40px;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    justify-content: space-between;
    width: 100%;

    .title{
        color:$autozone-black-color;
        font-family: 'AutoZoneCond-Heavy', sans-serif;
        font-size: 16px;
        margin-left: 16px;
        text-transform: uppercase;
    }

    .close{
        color: $autozone-black-color;
        cursor: pointer;
        font-family: Arial, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        font-weight: bold;
        letter-spacing: normal;
        margin-right: 16px;
    }
}