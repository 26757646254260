.body {
       position: relative;
}

@media screen and (max-width: 576px) {
       .body {
              padding: 0 16px;

              &.fullWidth {
                     padding: 0 8px;
              }
       }
}
