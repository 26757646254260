@import "../../../sass/css-variables";

.inputFile {
    position: relative;

    &, * { box-sizing: border-box }

    .label {
        background-color: white;
        margin-top: -10px;
        margin-left: 5px;
        position: absolute;
        z-index: 2;
    }

    .label-text {
        font-family: Arial, sans-serif;
        font-size: 12px;
        font-weight: bold;

        &:nth-child(1){
            color:#d52b1e;
        }

        &:nth-child(2){
            color:$regular-text-color;
        }
    }

    .input {
        border: solid 1px #c4c4c4;
        color: $autozone-black-color;
        display: block;
        font-family: Arial, sans-serif;
        font-size: 14px;
        min-height: 38px;
        padding: 11px 10px;

        [type="file"] {
            display: none;
        }

        img {
            border: 1px solid $gray-color;
            cursor: pointer;
            display: block;
            height: 202px;
            object-fit: contain;
            margin: 5px 2px;
            width: 202px;
        }
    }

    .error-message {
        color: $autozone-red-color;
        display: block;
        font-family: Arial, sans-serif;
        font-size: 11px;
        padding-top: 4px;
    }

    &.error {
        &:after {
            color: $autozone-red-color;
            content: '*';
            left: 10px;
            position: absolute;
            top: 12px;
        }

        .input {
            border-color: $autozone-red-color;
            padding-left: 24px;
        }
    }
}