@import "../../../sass/css-variables";
$orange: #f37f00;

.coupon-wrapper {
    overflow: hidden;
    pointer-events: none;

    &.md {
        min-height: 195px;
        width: 462px;
    }

    &.mobile {
        height: 122px;
        width: 292px;
    }

    &.sm {
        min-height: 110px;
        width: 260px;
    }

    &.xs {
        min-height: 62px;
        width: 142px;
    }

    &.pt {
        width: 492px;
    }
}

.CouponComponent {
    &, * { box-sizing: border-box }

    background-color: #fff;
    border: 1px solid $orange;
    /*box-shadow: 2px 2px 4px #0002;*/
    display: flex;
    min-height: 204px;
    overflow: hidden;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transform-style: flat;
    transform-style: flat;
    width: 484px;

    h2, h3, h4, h5, p {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
    }

    .leftPanel {
        color: #231f20;
        flex: 1;
        max-width: calc(484px - 216px);
        padding: 12px 13px 12px 12px;

        .contactInfo {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: center;

            li {
                display: inline-block;
                font-family: "AutoZoneCond", sans-serif;
                font-size: 10px;
                font-weight: normal;

                &:first-child {
                    margin-right: 12px;
                }

                &:last-child {
                    font-size: 9px;
                }

                img {
                    display: inline;
                    margin-right: 2px;
                }
            }
        }

        .disclaimerText {
            font-family: Arial, sans-serif;
            font-size: 12px;
            font-weight: normal;
            line-height: 1.2;
            margin: 0 0 6px 0;
            padding: 0;
            text-align: center;
        }

        .salesText {
            font-family: "AutoZoneCond", sans-serif;
            font-size: 16px;
            font-weight: normal;
            line-height: 1;
            margin-bottom: 10px;
            margin-top: 10px;
            text-align: center;
        }

        .titleBox {
            align-items: center;
            border: 1px solid $orange;
            display: flex;
            height: 66px;
            justify-content: center;
            margin-bottom: 8px;

            .title {
                color: $orange;
                font-family: "AutoZoneCond-Medium", sans-serif;
                font-size: 56px;
                text-align: center;
            }
        }
    }

    .rightPanel {
        background-color: $orange;
        background-image: url("../../../assets/orange-gif-bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 224px 210px;
        flex-shrink: 0;
        padding: 16px;
        position: relative;
        width: 216px;

        .bgImage { display: none }

        .expiration {
            bottom: 10px;
            color: #fff;
            font-family: "AutoZoneCond", sans-serif;
            font-size: 12px;
            font-weight: normal;
            left: 0;
            line-height: 1.7;
            position: absolute;
            right: 0;
            text-align: center;
        }

        .savingsTitleBox {
            align-items: center;
            background-color: #fff;
            display: flex;
            height: 75px;
            justify-content: center;
            left: 16px;
            padding: 0 10px;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            width: 184px;

            .savingsTitle {
                color: $orange;
                font-family: "AutoZoneCond", sans-serif;
                font-size: 30px;
                font-weight: normal;
                line-height: 0.97;

                strong {
                    font-family: "AutoZoneCond-Heavy", sans-serif;
                    font-weight: normal;
                }
            }
        }

        .shopAddress {
            color: #fff;
            font-family: "AutoZoneCond", sans-serif;
            font-size: 12px;
            font-weight: normal;
            line-height: 1;
            position: relative;
            text-align: center;
            text-transform: uppercase;
        }

        .shopName {
            color: #fff;
            font-family: "AutoZoneCond-Heavy", sans-serif;
            font-size: 13px;
            font-weight: normal;
            line-height: 1;
            margin-bottom: 3px;
            position: relative;
            text-align: center;
            text-transform: uppercase;
        }

        .validityText {
            bottom: 26px;
            color: #fff;
            font-family: "AutoZoneCond-Medium", sans-serif;
            font-size: 12px;
            font-weight: normal;
            left: 0;
            letter-spacing: normal;
            line-height: 1.5;
            position: absolute;
            right: 0;
            text-align: center;
        }
    }

    &.mobile {
        transform: $coupon-scale-mobile
    }

    &.mobile-sm {
        transform: $coupon-scale-mobile-sm
    }

    &.lg {
        transform: $coupon-scale-lg
    }

    &.md {
        transform: $coupon-scale-md
    }

    &.sm {
        transform: $coupon-scale-sm
    }

    &.xs {
        border-width: 4px;
        height: 230px;
        transform: $coupon-scale-xs;

        .leftPanel {
            .titleBox {
                border-width: 4px;
            }
        }
    }

    &.es {
        .leftPanel {
            .titleBox {
                height: 79px;
            }
        }

        .rightPanel {
            .savingsTitleBox {
                .savingsTitle {
                    font-family: "AutoZoneCond-Medium", Arial, sans-serif;
                }
            }
        }
    }

    @media print {
        width: 492px;

        &, * {
            -webkit-print-color-adjust: exact;
        }

        .leftPanel {
            max-width: calc(492px - 216px);
        }

        .rightPanel {
            overflow: hidden;

            &:before {
                border-bottom: 250px solid $orange;
                box-sizing: border-box;
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 0;
            }

            .bgImage {
                bottom: 0;
                display: block;
                left: 0;
                object-fit: cover;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 0;
            }
        }
    }
}
