@import "../../../../sass/css-variables.scss";

.alert {
    width: 1168px;
    height: 192px;
    border: solid 1px #c4c4c4;
    background-color: #ffffff;
    padding: 16px;
    box-sizing: border-box;
        
    .header{
        width: 100%;
        height: 40px;
        background-color: #9b9b9b;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .title{
            font-family: 'AutoZoneCond-Heavy', sans-serif;
            font-size: 16px;
            text-rendering: optimizeLegibility;
            -webkit-appearance: none;
            color:#231f20;
            margin: 12px 0 12px 16px;
            text-transform: uppercase;
        }

        .close{
            font-family: Arial;
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color:$autozone-black-color;
            margin-right: 16px;
            cursor: pointer;
        }
    }
    .content{
        box-sizing: border-box;

        p{
            font-family: Arial;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #231f20;
            margin-left: 16px;
            margin-bottom: 27px;
        }
    }
    .footer {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #c4c4c4;
        padding-top: 16px;       
    }

}

@media screen and (max-width: $breakpoint-lg) {
    .alert {
        width: 824px;
        height: auto;   
        max-height: 197px;        

        .header{
            width: 792px;
            height: 45px;
        }

        .content p{
            margin-bottom: 24px;
        }

    }
    
}
@media screen and (max-width: $breakpoint-sm) {
    .alert {
        width: 287px;
        height: auto;   
        max-height: 237px;        

        .header{
            width: 254px;
            height: 40px;
        }

        .content p{
            margin-bottom: 24px;
        }
    }
    
}