@import "../../../sass/css-variables.scss";
@import "../../../sass/global.scss";

.toggle-container{
    &, * { box-sizing: border-box }

    align-items: center;
    display: flex;
    height: 28px;    
    text-align: left;
        
    .toggle-legend {
        font-family: 'AutoZoneCond-Medium', sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: condensed;
        line-height: normal;
        letter-spacing: normal;
        display: inline-block;
        margin-left: 16px;
        
        a, a:visited, a:active, a:focus, a:hover {
            color: $autozone-orange-color;
            border: none;
            font-family: 'AutoZoneCond-Medium', sans-serif;
            font-size: 16px;
            font-weight: normal;
            line-height: normal;
            background: none !important;
            padding: 0;
            text-decoration: underline;  
        }
    }

    .toggle-item {
        background-color: transparent;
        text-rendering: optimizeLegibility;
        -webkit-appearance: none;
        cursor: pointer;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-family: 'AutoZoneCond-Heavy', Arial,  sans-serif;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        color: rgba(#3a3c3d, 0.3);
        display: flex;
        border: solid 1px #bfc0c0;
        width: 124px;    

        &:before {
            height: 16px;
            margin-right: 6px;
        }

        &:nth-child(1):before { content: url('../../../assets/gray_tick.svg') }
        &:nth-child(2):before { content: url('../../../assets/gray_close.svg') }

        &.active {
            &:nth-child(1) {
                background-color: #f1fff1;
                border-color: #008300;
                color: #008300;
                &:before {
                    content: url('../../../assets/green_tick.svg');
                }
            }
            &:nth-child(2) {
                background-color: #fff2f0;
                border-color: #d52b1e;
                color: #d52b1e;
                &:before {
                    content: url('../../../assets/red-close.svg');
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .toggle-container .toggle-item.first {
        width: 109px;
    }
}

@media screen and (max-width: 768px) {
       
    .toggle-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        
        div{
            display: flex;
        }

        .toggle-item {
            height: 28px;

            &.first, &.second {
                width: 128px;
            }
        }

        .toggle-legend{
            margin-top: 8px;
            margin-left: 0;
        }
    }
}
