@import "../../../sass/css-variables.scss";
@import "../../../sass/global.scss";

#termsnconditions{
    margin-bottom: 48px;
    h1.faq-header{
        font-family: 'AutoZoneCond-Heavy', sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin: 11px 0 10px 0;
    }
    .box{
        .header{
            span.title{
                font-size: 14px;
            }
        }
    }
    ul.bookmarks{
        margin: 0 auto 16px;    
        padding: 0;
        list-style: none;
        text-align: center;
        > li{
            display: inline;
            list-style-type: none;
            list-style-image: none !important;
            font-size: 14px;
            line-height: 1.5;
            text-align: center;
            color: #231f1e;
            > a, a:hover, a:visited, a:active{
                color: #f37f00;
                text-decoration: underline;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 14px;
            }
            > a.active{
                color: #6c6f70;
            }
        }
    }
   
    #terms{

        strong{
            font-family: 'AutoZoneCond-Medium', sans-serif;
            font-size: 16px;
            font-weight: normal;
        }

        p{
            font-size: 14px;
            line-height: 1.57;     

        }
        
    }
    @media screen and (max-width: 768px) {
        #terms{
            strong, p{
                text-align: justify;
            }
        }
    }
}
