@import '../../../sass/css-variables';
@import '../../../sass/mixins';

.Stepper {
    @include flex(column);
    align-items: center;

    @media screen and (max-width: 440px) {
        margin: 0 -10px;
    }

    .content {
        @include flex();
        margin: 0 auto 16px auto;

        &, * { box-sizing: border-box }

        .item {
            @include flex(column);
            align-items: center;
            margin-bottom: -3px;
            position: relative;
            width: $stepper-items-space + $stepper-items-circle-size;

            &:before, &:after {
                background-color: #cbcbcb;
                content: '';
                height: 6px;
                position: absolute;
                top: 16px;
                width: 50%;
                z-index: 0;
            }

            &:before { left: 0 }
            &:after { left: 50% }

            &:first-of-type:before { display: none }
            &:last-of-type:after { display: none }

            .mark {
                background-color: #f6f6f6;
                border: 1px solid #c4c4c4;
                border-radius: $stepper-items-circle-size / 2;
                height: $stepper-items-circle-size;
                position: relative;
                width: $stepper-items-circle-size;
                z-index: 1;

                &:after {
                    background-color: #fff;
                    border: 1px solid #c4c4c4;
                    border-radius: 8px;
                    box-sizing: border-box;
                    content: '';
                    height: 16px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 16px;
                    z-index: 1;
                }
            }

            .title {
                color: #9b9b9b;
                display: block;
                font-family: AutoZoneCond-Medium, sans-serif;
                font-size: 18px;
                font-stretch: normal;
                font-style: normal;
                font-weight: normal;
                letter-spacing: -0.2px;
                line-height: 1;
                text-align: center;
                text-transform: uppercase;
                white-space: nowrap;

                &:first-of-type {
                    margin-top: 11px;
                }

                &:not(:first-of-type) {
                    margin-top: 2px;
                }
            }

            &.current, &.completed {
                .title { color: $autozone-orange-color }
            }

            &.completed {
                cursor: pointer;
                &:before, &:after { background-color: $autozone-orange-color }

                .mark {
                    background-color: $autozone-orange-color;
                    border: none;

                    &:after {
                        background: transparent;
                        border: none;
                        content: url("../../../assets/checkmark/tick_mark.svg");
                        height: 12.3px;
                        width: 16px;
                    }

                    &.mobile:after {
                        margin-top: -2px;
                    }
                }
            }

            &.current {
                &:before { background-color: $autozone-orange-color }

                .mark:after {
                    border: 3px solid $autozone-orange-color;
                }
            }
        }

        @media screen and (max-width: 1024px) {
            width: 100%;

            .item {
                flex: 1;
                width: auto;
            }
        }
    }
}
