@import "../../../../sass/css-variables.scss";

.eligible{
    width: 608px;
    /*height: 425px;*/
    border: solid 1px #c4c4c4;
    box-sizing: border-box;
    background-color: #ffffff;
    padding: 16px;
    max-width: 100%;

    .header{
        width: 100%;
        height: 40px;
        background-color: #9b9b9b;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .title{
            font-family: 'AutoZoneCond-Heavy', sans-serif;
            font-size: 16px;
            font-weight: 900;
            color:$autozone-black-color;
            margin-left: 16px;
            text-transform: uppercase;
        }

        .close{
            font-family: Arial;
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color:$autozone-black-color;
            margin-right: 16px;
            cursor: pointer;
        }
    }

    .support{
        margin: 16px 0 13px 0;
        font-size: 14px;

        span{
            font-family: Arial;
            font-size: 14px;
            font-weight: initial;

            &:nth-child(1){
                font-weight: bold;
                color: $regular-text-color;
            }

            &:nth-child(4){
                color: $autozone-orange-color;
                text-decoration: underline;
                cursor: pointer;
            }

        }
        a, a:hover, a:visited, a:active{
            font-size: 14px;
            color: $autozone-orange-color;
        }
      
    }

    .dashed{
        width: 99%;
        /*height: 302px;*/
        border: dashed 3px $autozone-orange-color;
        background-color: #f8f8f8;        

        .earn{
            font-family: Arial;
            font-size: 26px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.31;
            letter-spacing: normal;
            color: $autozone-orange-color;
            margin: 24px 24px 0px ;
            display: block;
        }

        .price{
            color: $regular-text-color;           
            margin: 10px 16px 21px 24px;
            align-items: center;
            

            h1{
                display: inline-block;
                font-family: 'AutoZoneCond', Arial, sans-serif;
                font-weight: 500;
                font-style: italic;
                font-size: 100px;
                font-stretch: condensed;
                line-height: 1;
                letter-spacing: normal;
                margin: 0 16px 0 0;
            }

            p{
                border-left: 1px solid #c4c4c4;
                display: inline-block;
                font-family: Arial;
                font-size: 26px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.31;
                letter-spacing: normal;
                margin: 0 0 0 16px;
                width: 49%;
                padding: 5px 0 5px 16px;                
            }
           
        }

        .approved {
            font-family: 'AutoZoneCond-Medium', Arial, sans-serif;
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: condensed;
            line-height: 1.31;
            letter-spacing: normal;
            color: $regular-text-color;
            margin:0 0 7px 23px;
        }

        p {
                
            font-family: Arial, sans-serif;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.27;
            letter-spacing: normal;
            color: $regular-text-color;
            margin: 6px 23px 23px;
        }

        .content-es {
            .earn { font-size: 22px }

            ul {
                list-style: none;
                margin: 20px 44px 40px 44px;
                padding: 0;

                li {
                    color: $regular-text-color;
                    font-family: Arial, sans-serif;
                    font-size: 18px;
                    margin-bottom: 26px;
                    position: relative;

                    &:before {
                        border: 5px solid;
                        border-color: transparent transparent transparent $regular-text-color;
                        content: "";
                        position: absolute;
                        left: -20px;
                        top: 5px;
                    }
                }
            }
        }
        
    }

    @media screen and (max-width: 640px) {
        .dashed {
            .price {
                p {
                    border-left: none;
                    display: block;
                    margin-left: 0;
                    margin-top: 16px;
                    padding: 0;
                    width: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 400px) {
        .dashed {
            .approved {
                display: block;
                margin: 0 16px;
            }

            .earn {
                margin: 16px;
            }

            .price {
                margin: 16px;

                h1 {
                    margin: 0 0 0 -8px;
                }
            }

            > div > p:last-child {
                margin: 16px;
            }
        }

        .header {
            height: 58px;

            .close {
                white-space: nowrap;
            }
        }

        .support {
            span:first-of-type {
                display: block;
            }
        }
    }
}
