@import "../../../sass/css-variables.scss";
@import "../../../sass/mixins.scss";

button{
    border-collapse: collapse;
    border-style: solid;
    border-width: 1px;
    outline: none;
    cursor: pointer;
    // position: relative;

    span {
        font-family: 'AutoZoneCond-Heavy', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: condensed;
        text-align: center;
        letter-spacing: normal;
        text-transform: uppercase;
        // left: 50%;
        // position: absolute;
        // top: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }
}