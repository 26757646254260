@import "../../../sass/css-variables.scss";
@import "../../../sass/global.scss";

.container {
    margin: 0;          
    border: solid 1px #c4c4c4;        
    padding: 24px;    
    padding-bottom: 0; 

    h2.check-icon{
      font-family: 'AutoZoneCond-Medium';
      font-size: 24px;
      font-weight: normal;
      font-stretch: condensed;
      font-style: normal;
      line-height: 0.5;
      letter-spacing: normal;
      color: #231f20;
      display: block;
      margin-bottom: 15px;
    }    

    p{                  
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        line-height: 24px;
        color: #231f20;
        display: block;       

        a, a:hover, a:visited, a:active{
          font-size: 18px;
          font-style: normal;            
          color: $autozone-orange-color;        
          cursor: pointer;    
        }
        span.clickable{
          margin-top: -3px;
        }
        .styled-checkbox {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it
          
            & + label {
              position: relative;
              cursor: pointer;
              padding: 0;
            }
          
            // Box.
            & + label:before {
              content: '';
              margin-right: 10px;
              display: inline-block;
              vertical-align: text-top;
              width: 16px;
              height: 16px;
              background: white;
              border: 1px solid #c4c4c4;
            }
                     
            // Box checked
            &:checked + label:before {
              background: #f35429;
            }
            
            // Disabled state label.
            &:disabled + label {
              color: #b8b8b8;
              cursor: auto;
            }
          
            // Disabled box.
            &:disabled + label:before {
              box-shadow: none;
              background: #ddd;
            }
          
            // Checkmark. Could be replaced with an image
            &:checked + label:after {
              content: '';
              position: absolute;
              left: 4px;
              top: 8px;
              background: white;
              width: 2px;
              height: 2px;
              box-shadow: 
                2px 0 0 white,
                4px 0 0 white,
                4px -2px 0 white,
                4px -4px 0 white,
                4px -6px 0 white,
                4px -8px 0 white;
              transform: rotate(45deg);
            }
          }        

    }
   
    span{
        font-size: 18px;
        line-height: 1.33;

        a{
          color: #4278a0 !important;  
          display: inline-block;          
        }    
    }    
}
@media screen and (max-width: 768px) {
  .container {            
    padding: 16px;      
  }  
}