@import "../../../../../../sass/css-variables.scss";

.timePicker{
    &, * { box-sizing: border-box }
    opacity: 0.6;
    pointer-events: none;
    position: relative;

    &.active {
        opacity: 1;
        pointer-events: auto;
    }

    .timePickerList{
        position: absolute;
        z-index: 999;
        border: solid 1px #c4c4c4;
        border-top: none;
        background: white;
        max-height: 139px;
        overflow-y: auto;
        width: 100%;
      }

    .timer{
        box-sizing: border-box;        
        height: 38px;
        border: solid 1px #c4c4c4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 auto;

        &.opened {
            border-color: $autozone-black-color;
            box-shadow: 0 0 4px #0002;
        }

        span{
            font-family: Arial, sans-serif;
            font-size: 14px;
            font-weight: normal;
            color: $autozone-black-color;
        }
    }

    .openHours{
        margin: 0 0 16px;
    }

}