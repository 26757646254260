.boxes-horizontal{
    margin-bottom: 16px;
}

.stepTwo {
    .col {
        .box {
            height: 100%;
        }
    }
}