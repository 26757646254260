@import "../../../sass/css-variables.scss";

.findshop {
    box-sizing: border-box;
    max-width: 100%;
    width: 1100px;
    margin: 0 auto;

    p.breadcrumb, p.para, .src-header{    
        margin-top: 10px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 14px;    
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #202124;

        > span, a, a:hover, a:visited, a:active{
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 12px;       
            color: #5b5c5d;
            text-decoration: none;
        }
        > span{
            margin: 0 8px;
        }
        > a.active{
            color: #a9aaa8;
        }        
    }

    p.para{
        line-height: 1.5;
        margin-bottom: 14px;
        margin-top: 0;

        @media screen and (max-width: 567px) {
            line-height: 1.43;
        }
    }

    h1.hdr-title{
        display: flex;
        margin: 0;
        span.title{
            font-family: 'AutoZoneCond', sans-serif;
            font-size: 41.5px;
            font-weight: normal;
            font-style: normal;
            font-stretch: condensed;
            line-height: normal;
            letter-spacing: normal;
            color: #202124;
        }
        span.print-logo {
            display: none;
            text-align: right;
        }
        @media screen and (max-width: 567px) {
            font-size: 33.2px;
            line-height: 0.9;
            margin-top: 6px;
        }
    }
    span.print-address {
        display: none;
    }
    .map {
        max-width: 100%;
        width:  1100px;
        height: 399px;
        margin: 0;

        @media screen and (max-width: 567px) {
            height: 300px;
        }
    }

    /*Resize the wrap to see the search bar change!*/
    .wrap {
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;

        .search {
            position: relative;
            height: 56px;
            z-index: 1000;
        }
        
        .searchButton, .locationButton{
            cursor: pointer;
            background-color: #000000;
            background: url('../../../assets/search-big.svg') left center no-repeat;
            border-color: #000;
            border-style: none;
            margin: 0;
            object-fit: contain;
            width: 56px;
        }

        .locationButton{
            background: url('../../../assets/location.svg') left center no-repeat;
            
        }

        .miles-radius{
            display: flex;
            //flex: 0 0 21%;            
            align-self: center;

            .select-box{
                display: flex;
                align-items: center;
                margin-right: 16px;
                
                span{
                    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    text-align: right;
                    color: #484848;
                }

                .mileage-radius{
                    margin: 0 8px;
                                 
                    .radiusDropDown__control{
                        border: solid 1px #a9aaa8;
                        display: flex;
                                                                       
                        .radiusDropDown__value-container{
                            font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1;
                            letter-spacing: normal;
                            text-align: right;
                            color: #484848;
                        }
                       
                    }
                    .radiusDropDown__control.radiusDropDown__control--is-focused.radiusDropDown__control--menu-is-open{
                        .radiusDropDown__indicators{
                            .radiusDropDown__indicator.radiusDropDown__dropdown-indicator{                                
                                transform: rotate(180deg);
                            }
                        } 
                    }
                    .radiusDropDown_menu{
                        border: solid 1px #a9aaa8;
                        border-radius: 0;
                    }

                }
                
            }
            .tools{
                align-self: flex-end;
                margin-top: 0;
            }
        }
      
    }

    .tools {
        text-align: right;
        margin: 14px 0 0;

        @media screen and (max-width: 567px) {
            margin-top: 7px;
        }

        .printButton, .emailButton, .addButton{
            width: 40px;
            height: 24px;
            object-fit: contain;
            border: none;
            outline: none
        }
        .printButton{        
            background: url('../../../assets/print.svg') left center no-repeat; 
        }
        .emailButton{
            background: url('../../../assets/email.svg') left center no-repeat;      
            margin-left: 10px;   
        }
        .addButton{
            background: url('../../../assets/rectangle.svg') left center no-repeat;
            margin-left: 10px; 
        }
    }
    .srcheader{
        display: none;
    }

    .itemResult_content .tools {
        margin-top: 24px;
    }

    #map-container {
        .banner {
            height: auto;
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .firestone-pencil {
                background: url("../../../assets/firestone-desktop-pencil.png") no-repeat center center;
                width: 100vw;
                height: 94px;
                background-size: contain;
                position: relative;
                background-position: center center;
                @media screen and (max-width: 640px) {
                    background: url("../../../assets/firestone-mobile-pencil.png") no-repeat center center;
                    height: 130px;
                    background-size: 100% 100%;
                    background-position: center center;
                }
              }

            .fix-finder-pencil {
              background: url('https://www.autozone.com/cdn/images/B2B/Content/misc/fy24p12-findastore-d.webp') no-repeat center center;
              width: 100vw;
              height: 147px;
              background-size: 100% 100%;
              position: relative;
              background-position: center center;
              border: 1px solid #eae7e7;

              @media (min-width: 641px) and (max-width: 1024px) {
                background: url('https://www.autozone.com/cdn/images/B2B/Content/misc/fy24p12-findastore-t.webp') no-repeat center center;
                  background-size: 100% 100%;
                  height: 160px;
              }

              @media screen and (max-width: 640px) {
                  background: url('https://www.autozone.com/cdn/images/B2B/Content/misc/fy24p12-findastore-m.webp') no-repeat center center;
                  background-size: 100% 100%;
                  height: 160px;
              }
            }

              a {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  text-decoration: none;
                  z-index: 10;
              }
        }
    }

    .src-header{
        background-color: #f2f2f2;
        border-bottom: 1px solid #e5e5e5;
        min-height: 52px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .filters-wrapper {
            margin-bottom: -8px;
            margin-top: 8px;
        }

        @media screen and (max-width: 567px) {
            border-bottom: none;

            .filters-wrapper {
                margin-bottom: 8px;
                margin-left: 16px;
                margin-top: 6px;
                min-width: 90%;
            }
        }

        p, p > span {        
            margin: 0;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #202124;                 
        }

        
        p.src-tools{
            display: flex;
            margin-right: 16px;

            .filterButton, .filterLink{
                min-width: 33px;
                height: 40px;
                background: url('../../../assets/filter.svg') left center no-repeat;        
                border: none;
                outline: none;        
                margin-top: 5px;      
                padding: 0;

                @media screen and (max-width: 768px) {
                    min-width: 30px;
                }
            }   

            .filterLink{
                background: none;
                text-decoration: underline;
                margin-left: 5px;
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.25;
                letter-spacing: normal;
                color: #484848;
                white-space: nowrap;
            }
        }

        .src-results {
            margin: 16px;
            flex-direction: row;        
            flex: 1;

            span {
                font-size: 14px;
            }
        }
    }
    /*
    Pagination
    */

    ul.pagination {
        list-style: none;            
        display: flex;
        justify-content: center;
        margin: 26px 0 0 0;
        padding: 0;

        > li{
            list-style-type: none;
            display: inline-block;
            margin: 16px;

            > a, a:hover, a:visited, a:active, .link, .link.active {
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 18px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #787878;
                text-decoration: none;
                
            }        
            > a.prev, a.next, .link.prev, .link.next {
                font-family: AutoZoneCond-Medium, sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: condensed;
                line-height: 1.13;
                letter-spacing: 0.75px;
                text-align: center;
                color: #a9aaa8;
                text-decoration: line-through;
            }
            > a.first, a.last, .link.first, .link.last{
                width: 12px;
                height: 7px;
                object-fit: contain;
                display: block;
                margin-top: 5px;
            }
            > a.first, .link.first {
                background: url(../../../assets/left-arrow.svg) left center no-repeat;
                &.active {
                    background: url(../../../assets/left-arrow-active.svg) left center no-repeat;
                }
            }
            > a.last, .link.last {
                background: url(../../../assets/right-arrow.svg) left center no-repeat;
                &.active {
                    background: url(../../../assets/right-arrow-active.svg) left center no-repeat;
                }
            }
            > a.active, .link.active {
                color: #202124;
                text-decoration: none;
            }
        }

        @media screen and (max-width: 567px) {
            justify-content: space-between;

            > li { margin: 16px 0 }
        }

        .number {
            a, .link {
                bottom: 2px;
                position: relative;
            }
        }
    }
/*
 Search Result 
*/

    .address-card {
        display: flex;
    }

    .srcresults {
        margin-top: 24px;
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 26px;

        &, * { box-sizing: border-box; }

        .itemResult_info {
            display: flex;
            display: -webkit-flex;

            .itemResult_marker {
                margin-right: 16px;
            }

            .itemResult_content{
                .badge{
                    cursor: pointer;
                    display: block;
                    margin: 16px 0 0;

                    img {
                        height: 27px;
                        width: 145px;
                    }
                }
                #address{
                    margin-top: 0;

                    span{
                        display: block;
                        font-family: 'Helvetica Neue', Arial, sans-serif ;
                        font-size: 16px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #202124;
                        margin-bottom: 11px;
                    }

                    span.title{
                        cursor: pointer;
                        font-family: "AutoZoneCond", sans-serif;
                        font-size: 20px;
                        margin-top: 3px;

                        strong {
                            font-family: "AutoZoneCond-Medium", sans-serif;
                            font-weight: 500;
                        }

                        @media screen and (max-width: 567px) {
                            &.hours-title {
                                margin-left: 12px;
                                margin-top: 16px;
                            }
                        }
                    }
                    .address-card{
                        .card-info{
                            span:first-of-type{
                                margin-bottom: 0;
                            }

                            span.address{
                                line-height: 1.5;
                            }
                        }
                    }

                    .phnumber{
                        text-decoration: underline;
                        color: #5b5c5d;
                    }
                }
            }
        }

        #storehrs{
            margin: 0;

            span{
                display: block;
                font-family: 'Helvetica Neue', Arial, sans-serif ;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #202124;
            }

            span.title{
                cursor: pointer;
                font-size: 18px;
                font-weight: 400;
                margin-top: 3px;
                margin-bottom: 11px;

                strong {
                    font-weight: 600;
                }
            }

            .table{
                display: flex;
                flex-direction: column;
                width:100%;
                border-collapse:collapse;
                position: relative;
                margin-bottom: 0;
            }

            .table-row{
                display: flex;
                margin-bottom: 0;
            }

            .table-cell{
                flex: 1;
                padding: .6em 0;
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 14px;
                font-weight: normal;
                height: 32px;
                margin-bottom: 0;
            }

            .table-head {
                background-color: #e5e5e5;
                margin-left: -16px;
                margin-right: -16px;
                padding-left: 16px;
                padding-right: 16px;

                > span{
                    font-weight: bold;
                }
            }

            @media screen and (max-width: 768px) {
                margin-right: 40px;

                .table-head {
                    margin-right: 0;
                    padding-right: 0;
                }
            }

            @media screen and (max-width: 567px) {
                margin-right: 0;
                margin-top: 24px;
                span.title{
                    margin-left: 12px;
                }
                .table-head {
                    margin-left: 0;
                    padding-left: 12px;
                }

                .table-row {
                    padding-left: 12px;
                }
            }
    }

        p#tools{ 
            display: flex;
            text-align: left;
        }
        
        span.icon-marker{
            box-sizing: border-box;
            color: white;
            font-family: AutoZoneCond, sans-serif;
            font-size: 10px;
            font-weight: 500;
            font-style: normal;
            font-stretch: condensed;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            padding-top: 5px;
            display: block;
            width: 22px;
            height: 28px;
            object-fit: contain;            
            background: url('../../../assets/map.svg') left center no-repeat;
            background-size: cover;

            @media screen and (max-width: 567px) {
                height: 25px;
                width: 19.10px;
            }
        }

        span.icon-smallmarker{
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-weight: normal;
            display: flex;
            justify-content: center;

            &:before {
                object-fit: contain;            
                content: url('../../../assets/mapsmall.svg');
                margin-right: 5px;                
            }

            @media screen and (max-width: 567px) {
                justify-content: flex-start;
                padding-left: 36px;
            }
        }

        span.directions{
            > a, a:hover, a:visited, a:active{
                cursor: pointer;
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.25;
                letter-spacing: normal;
                color: #606162;
                text-decoration: underline;
            }

            @media screen and (max-width: 567px) {
                margin-left: -6px;
            }
        }

    }



    @media screen and (max-width: 577px) {
        .srcresults {
            padding-bottom: 19px;
        }

        .wrap {
            flex-direction: column;
            .search{
                flex: 0 0 100%;
                .predictiveSearch {
                    width: 70%;
                    .searchTerm{
                        font-size: 14px;
                    }
                }
            }
            .miles-radius{
                flex: 1;
                margin: 21px 0 6px 0;
                align-self: flex-end;

                .select-box{
                    margin-right: 21px;
                }
            }
            .searchButton, .locationButton{
                background-size: 100%;
                object-fit: contain;
                height: 40px;
                width: 40px;
            }
        }
    }
}

/* Rewriting google css box rules */
.pac-item.active {
    background-color: #f5f5f5;
  }
.pac-container {
    padding: 20px 16px;
    background-color: #ffffff;

    &:after {
        display: none;
    }

    &:before {
        display: block;
        color: #787878;
        content: "search suggestions"  "#{'\A'}"  "Choose from the drop-down";
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: 12px;
        padding: 0 0 16px 3px;
        text-transform: uppercase;
        white-space: pre-line;
        line-height: 1.5;
    }

    .pac-item {
        border-top: none;
        line-height: 32px;
        
        &, .pac-item-query, .pac-matched, > span {
            color: #202124;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 16px;
        }

        .pac-icon {
            display: none;
        }
    }

    @media screen and (max-width: 567px) {
        width: calc(100% - 32px) !important;
    }
}

@media print{
    .findshop{

        &, *{ box-sizing: border-box; }

        width: 100%;
        margin: 0 auto !important;
        max-width: 595px;
        padding: 0 12px;

        p.breadcrumb, p.para, .wrap, .tools, .map, .src-header, ul.pagination, .DisclaimerText{
            display: none;
        }
        span.print-address{
            font-family: Arial, sans-serif;
            font-size: 8px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            display: block;
            padding: 0 0 6px 0;
        }
        h1.hdr-title{
            align-items: flex-start;
            display: flex;
            margin: 0 0 6px 0;
            padding: 0;

            span.title{
                display: block;
                font-family: Arial, sans-serif;
                font-size: 20px;
                font-weight: 800;
                font-stretch: condensed;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000000;
                flex: 1;
                padding: 0;

                &.es {
                    font-size: 18px;
                }
            }

            span.print-logo{
                display: block;
                flex: 0;
                padding: 0;
                img{
                    display: block;
                    margin-top: 6px;
                }
            }

        }

        .srcheader{
            display: flex;
            align-items: stretch;
            border: 1px solid #dadada;
            width: 100%;
            max-width: 571px;
            box-sizing: border-box;

            div{
                font-family: Arial, sans-serif;
                font-size: 10px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000000;
                padding-top: 4px;
                padding-bottom: 5px;
                padding-left: 8px;
                padding-right: 8px;
                border-right: 1px solid #dadada;
                display: inline-block;
                box-sizing: border-box;
            }
            div:nth-child(1){
                width: 36px;
            }
            div:nth-child(2){
                width: 254px;
            }
            div:nth-child(3){
                width: 178px;
            }
            div:nth-child(4){

                border-right: none;
                width: 70px;
            }
        }

        .srcresults{
            margin: 0;
            border: 1px solid #dadada;
            border-top: none;
            //width: 100%;
            width: 571px;
            height: 93px;
            box-sizing: border-box;
            position: relative;
            padding-bottom: 0;

            .itemResult_info{
               //display: inline-block;
               border-right: solid 1px #dadada;
               width: 290px;
               box-sizing: border-box;

                span{
                    font-family: Arial, sans-serif;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #000000;
                    font-size: 8px !important;
                    box-sizing: border-box;

                }

                .itemResult_marker{
                    width: 39px;
                    margin-right: 0;
                    border-right: solid 1px #dadada;
                    display: inline-block;
                    span.icon-marker{
                        background: none;
                        padding: 8px 0;
                    }
                }

                .itemResult_content{
                   width: 267px;
                   height: 91px;
                   padding: 4px 8px 0;
                   display: inline-block !important;

                    #address{
                        margin: 0;
                        display: inline-block;

                        span{
                            margin: 0;
                            text-transform: capitalize;
                        }
                        span.title{
                            font-family: Arial;
                            font-size: 10px !important;
                            display: block ;
                        }
                        span.address-card{
                            display: inline-block !important;
                            span.card-info{
                                span.address{
                                    display: inline-block !important;
                                }
                            }
                        }

                        a.phnumber{
                            display: inline-block !important;
                            font-size: 8px !important;
                            text-decoration: none;
                        }
                    }

                    .badge{
                        //width: 114px;
                        height: 18px;
                        background-color: #727272;
                        margin: 8px 0 0;
                        display: block;

                        img{
                            display: block;
                            width: 114px;
                            height: 18px;
                        }
                    }
                }

            }

            #storehrs{
                box-sizing: border-box;
                display: inline-block;
                width: 169px;
                //height: 93px;
                padding: 8px 0;
                border-right: solid 1px #dadada;
                border-left: none;
                margin: 0 0 0 0;
                position: absolute;
                left: 115px;
                span.title{
                    display: none;
                }

                span.table{
                    // width: 99%;
                    margin: 0;
                    padding: 0;

                    span.table-row{
                        padding: 0;
                        margin: 0;

                        span.table-cell{
                            padding: 0.055em 0;
                            height: inherit;
                            font-family: Arial;
                            font-size: 9px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.22;
                            letter-spacing: normal;
                            color: #000000;
                            width: 60px;
                        }
                    }
                    .table-head {
                        background: none;
                    }
                }
            }

            .icon-smallmarker{
                box-sizing: border-box;
                display: inline-block;
                padding: 8px;
                //width: 74px;
                height: 93px;
                font-family: Arial, sans-serif;
                font-size: 8px !important;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000000;
                position: absolute;
                left: 80px;
                white-space: nowrap;

                &:before {
                    transform-origin: top left;
                    transform: scale(0.8);
                }

                strong {
                    font-weight: normal !important;
                    margin-left: -2px;
                    margin-top: 2px;
                }
            }
            p#tools, span.directions, .hideme{
                display: none;
            }
        }
    }
}
