@import "../../../sass/css-variables.scss";

.dropdown{
  position: relative;
  width: 100%;

  &, * { box-sizing: border-box }

  .error-message {
    color: $autozone-red-color;
    display: block;
    font-family: sans-serif;
    font-size: 11px;
    padding-top: 6px;
    margin-bottom: -4px;
  }

  .floating{
    font-size: 12px;
    margin-left: 5px;
    margin-top: -7px;
    padding-left: 6px;
  }

  .resting{
    color: $autozone-black-color;
    font-weight: normal;
    margin-top: 12px;
    opacity: 0.6;
  }

  label {

    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color:$regular-text-color;
    position: absolute;
    margin-left: 12px;
    background-color: white;
    z-index: 1;

    .span {
      color:#d52b1e;
      display: inline;
    }

  }

  .head{
    border: solid 1px #c4c4c4;
    cursor: pointer;
    background-color: white;
    display: flex;
    flex-direction: row;
    height: 38px;
    padding-right: 24px;
    position: relative;
    z-index: 0;

    &.opened {
      border-color: $autozone-black-color;
      box-shadow: 0 0 4px #0002;
    }
  
    input{
      background-color: transparent;
      width: 100%;
      min-width: 100px;
      height: 100%;
      border: none;
      cursor: pointer;      
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: normal;
      color:$regular-text-color;
      padding-left: 12px;
      pointer-events: none;
      flex: 1;
      outline: none;
    }

    img{
      // margin-left: 13px;
      // display: inline;
      position: absolute;
      top: 30%;
      right: 10px;
    }
    
  }

  &.error .head {
    border-color: $autozone-red-color;
  }

  .dropDownBody{
    position: absolute;
    z-index: 999;
    border: solid 1px #c4c4c4;
    border-top: none;
    box-sizing: border-box;
    background: white;
    max-height: 300px;
    overflow: auto;
    width: 100%
  }

  select {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    .dropDownBody {
      max-height: none;
      overflow: visible;

      .checkboxGroup {
        margin-left: -12px;
        margin-right: -12px;
        margin-top: -16px;

        .checkBox {
          border-bottom: 1px solid #c4c4c4;
          box-sizing: border-box;
          height: 40px;
          margin: 0;
          padding: 0 12px;
          position: relative;

          .check {
            position: absolute;
            right: 12px;
          }

          .text { margin-left: 0 }
        }

        .contentCheckBox {
          flex-direction: column;

          .half {
            flex: 1;

            .checkBox { margin-bottom: 0 }
          }
        }

        .divider { display: none }

        .group-footer {
          padding: 12px;

          button { width: 100% }
        }
      }
    }
  }
}
