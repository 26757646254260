@import "../../../sass/css-variables.scss";
@import "../../../sass/global.scss";

.body-header {
  border-bottom: 1px solid #9b9b9b;
  
  p {
    display: inline-block;
    margin: 0 8px !important;
    padding-right: 16px;
    border-right: 1px solid #c4c4c4;
    
    span{    
      font-family: 'AutoZoneCond-Medium', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: condensed;
      line-height: 1.14;
      letter-spacing: normal;

      a.details, a.details:hover, a.details:active, a.details:visited{
        border-bottom: 1px solid $autozone-orange-color;
        font-family: 'AutoZone-Cond', sans-serif;
        font-size: 10px !important;
        font-weight: normal;
        margin-left: 2px;
        cursor: pointer;
        display: inline;
      }
    }

    span:nth-child(1){                     
      color: $autozone-orange-color;
      display: inline;
    }     
  
    span:nth-child(2){
      color: $autozone-black-color;
      font-family: AutoZoneCond-Heavy, sans-serif;
      font-stretch: condensed;
      line-height: 1.14;
      display: block;      
    }  

    span.faq{
      font-family: Arial, sans-serif !important;
      font-size: 10px;
      font-stretch: normal;
      font-weight: normal;      
      display: block;
    }
  }
  
  p:nth-child(3){
    margin-right: 0 !important;
    border-right: none;  
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 12px;

    .col {
      &:first-of-type {
        align-items: center;
        display: flex;
        margin-bottom: 12px;
        padding-bottom: 3px;

        h1 {
          flex: 1;
          margin: 0;
          padding: 5px 0;
        }

        hr {
          background-color: #9b9b9b;
          bottom: 0;
          border: none;
          height: 1px;
          left: 16px;
          margin: 0;
          position: absolute;
          right: 16px;
        }

        p {
          border-left: 1px solid #c4c4c4;
          padding: 0 16px;
        }
      }
    }

    p {
      border-right: none;
    }
  }

  @media screen and (max-width: 440px) {
    .col {
      &:first-of-type {
        margin-bottom: 0;
      }
      &:last-of-type {
        p {
          margin-left: 0 !important;
        }

        p:first-of-type {
          margin-bottom: 10px !important;
        }
      }
    }
  }
}