.CouponPage {
    padding: 12px;

    header {
        align-items: center;
        border-bottom: 1px solid #dadada;
        display: flex;
        margin-bottom: 48px;

        .brand {
            flex: 0;
        }

        .info {
            flex: 1;
        }

        h1 {
            font-size: 20px;
            margin: 0;
            padding: 0;
        }

        p {
            font-size: 8px;
            margin: 6px 0 8px;
            padding: 0;
        }
    }

    .content {
        margin: 0 auto;

        .coupon-wrapper {
            margin: 0 auto;
        }
    }
}
