@import "../../../../../sass/mixins";
@import "../../../../../sass/css-variables";

.ImageModal {
    @include flex(column);

    background-color: white;
    min-height: 200px;
    min-width: 300px;
    padding: 16px;
    box-sizing: border-box;
    height: 440px;
    margin: 0 auto;
    position: relative;
    width: 576px;
    max-height: 90%;
    max-width: 90%;

    img {
        max-height: 100%;
        max-width: 544px;
    }

    .image-container {
        @include flex(column);

        align-items: center;
        -webkit-align-items: center;
        border: 1px solid $lightgray-color;
        flex: 1;
        -webkit-flex: 1;
        justify-content: center;
        -webkit-justify-content: center;
        line-height: 1px;
        margin-top: 16px;
        overflow: hidden;
    }
}