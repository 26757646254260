.userProfile {
    .col {
        .box {
            height: 100%;            
        }
    }
    
    .couponsarea{             
        margin-top: 4px;


        h3{
            font-family: 'AutoZoneCond-Medium', sans-serif;
            font-size: 18px !important;
            font-weight: normal;
            color: #231f20;;            
            padding-right: 10px;
        }
    
        h3{
            margin: 0;
            padding: 0;
        }

        .coupon{
            margin-top: 11px;
            color: #6c6f70;
            
            img{
                display: block;
            }
        }

        p{
            margin: 3px 0 14px;

            a, a:hover, a:visited, a:active{
                cursor: pointer;
                font-family: Arial, sans-serif;
                font-size: 15px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #f37f00;
                text-decoration: none;
            }

            span:after{
                content: "\007C";
                color: #6c6f70;
            }
        }

        .coupon-container.deleted {
            h3, .coupon {
                opacity: 0.5;
            }
        }

        .gutter-space{
            margin-right: 142px;
        }  

        @media only screen and (max-width: 1024px) {
            .gutter-space{
                margin-right: 92px;
            }
        }
    }

    .addcoupons{
        height: 62px;
        border: solid 1px #c4c4c4;
        background-color: #f8f8f8;
        border-top: none;
        margin-bottom: 16px;
        text-align: right;
        box-sizing: border-box;

        .smallbutton {
            width: 134px;
            height: 28px;
            border: solid 1px #3a3c3d;
            background-color: #ffffff;
            margin: 16px;
            display: inline-block;
            font-family: AutoZoneCond-Heavy;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: condensed;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #3a3c3d;
            text-transform: uppercase;            
            box-sizing: border-box;
            padding-left: 24px;
            background: url(../../../assets/icon-add.svg) 11px center no-repeat;

            &.es {
                width: 159px
            }
                        
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 48px;
    }

    @media only screen and (max-width: 768px) {
        .couponsarea .gutter-space{
            margin-right: 92px;
        }

        .informationButtonContainer{
            height: 87px;
            text-align: left;
        }
        .footer {
            flex-direction: column-reverse;

            button{
                width: 100% !important;
                margin-bottom: 16px;

               &:nth-child(1){
                    margin-bottom: 0;
                }
            }
            
            
        }

    }

    
}
