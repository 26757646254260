.DisclaimerText {
    color: #202124;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    margin-bottom: 64px;
    margin-top: 36px;

    p {
        font-family: inherit;
        font-size: inherit;
        line-height: 1.3;
        margin: 0;
        padding: 0;
    }

    strong {
        display: block;
        font-weight: bold;

        &:first-of-type {
            margin-bottom: 16px;
        }

        &:nth-child(n+3) {
            margin-top: 16px;
        }
    }

    @media print {
        display: block !important;
    }
}
