@import "../../../sass/css-variables.scss";
.AccountPage {
    margin-bottom: 48px;
    .body-header,
    .box-group {
        margin-bottom: 16px;
    }

    fieldset.coupons {
        margin: 16px 0 17px;
        border: solid 1px #c4c4c4;
        border-left: none;
        border-right: none;
        border-bottom: none;
        padding: 0;

        legend, h3{
            font-family: 'AutoZoneCond-Medium', sans-serif;
            font-size: 18px !important;
            font-weight: normal;
            color: #231f20;;            
            padding-right: 10px;
        }

        h3{
            margin: 0;
            padding: 0;
        }

        .coupon {
            margin: 12px 0 0 0;
            overflow: hidden;
            width: 142px;
        }

        .coupon-col {
            &:nth-of-type(n+5) {
                margin-top: 16px;
            }
        }

        @media screen and (max-width: 1020px) {
            margin-top: 16px;

            .coupon-col {
                &:nth-of-type(n+4) { margin-top: 16px }
            }
        }

        @media screen and (max-width: 768px) {
            .coupon-col {
                &:nth-of-type(n+3) { margin-top: 16px }
            }
        }

        @media screen and (max-width: 576px) {
            .coupon-col {
                &:nth-of-type(n+2) { margin-top: 16px }
            }
        }
    }
}
