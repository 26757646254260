@import "../../../../sass/css-variables.scss";
@import "../../../../sass/mixins";

table#weekdayselect{
    border: solid 1px #c4c4c4;
    // border-top: none;
    width: 100%;

    thead{            
        background-color: #f8f8f8;        

        th{            
            height: 48px;            
            vertical-align: middle;
            border-bottom: solid 1px #c4c4c4;
            border-right: 1px solid #c4c4c4;
            padding: 0 24px;

            @media screen and (max-width: $breakpoint-lg) {
                padding: 0 14px;
            }

        &:nth-child(8){
                border-right: none;
            }
        }
         
       
    }
    tbody {
        
        td{
            padding: 16px 24px;
            vertical-align: middle;
            border-right: 1px solid #c4c4c4;
            width: calc(100%/8);

            &:first-child {
                padding: 0 16px;
            }

            @media screen and (max-width: $breakpoint-lg) {
                padding: 16px 14px;
                width: auto;

                &:first-child {
                    padding: 0 5px 0 6px;
                }
            }

            > span{
                font-family: Arial;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: normal;
                color: #231f20;
                display: block;
                margin: 0 0 25px;
            }

            &:nth-child(8){
                border-right: none;
            }
        }
    }

    /* Here goes the magic */
    @media screen and (max-width: $breakpoint-md) {
        & {
            box-sizing: border-box;

            * {box-sizing: border-box}

            @include flex(row);

            tr, td, th { display: block; }

            thead, tbody { @include flex(column); }

            thead th:first-of-type,
            tbody td:first-of-type {
                background-color: #f8f8f8;
                border-right: none;
                height: 40px;
            }

            thead th {
                &:last-of-type {
                    border-bottom: none;
                    border-right: 1px solid #c4c4c4;
                }

                .checkBox {
                    @include flex(column);
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    .text {
                        margin-left: 0;
                        margin-top: 8px;
                    }
                }
            }

            thead th:not(:first-of-type),
            tbody td:not(:first-of-type) {
                height: 71px;
                padding: 0;
            }

            tbody {
                flex: 1;

                td, .timePicker { @include flex(row) }

                td {
                    border-bottom: 1px solid #c4c4c4;
                    border-right: none;

                    &:last-of-type { border-bottom: none }

                    > span {
                        flex: 1;
                        margin-top: 12px;
                        text-align: center;
                    }

                    .timePicker {
                        align-items: center;
                        flex: 1;
                        padding: 8px;

                        > * {
                            flex: 1;
                            margin: 0;
                            position: relative;
                        }

                        .openHours .timer { border-right: none }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $breakpoint-sm) {
        & {
            thead th {
                padding: 0;
                width: 55px;
            }
        }
    }
}