.predictiveSearch {
    overflow: hidden;
    position: relative;

    &, * {box-sizing: border-box}

    .searchLabel {
        color: #a9aaa8;
        font-family: Helvetica, Arial, sans-serif;
        font-weight: normal;
        left: 17px;
        letter-spacing: 1.2px;
        line-height: 1.06;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        right: 0;
        text-overflow: ellipsis;
        top: 20px;
        white-space: nowrap;

        -webkit-animation: all 500ms ease-out;
        animation: all 500ms ease-out;
    }

    .searchTerm {
        background-color: #ffffff;
        border: solid 1px #a9aaa8;
        color: #111;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        height: 56px;
        letter-spacing: 1px !important;
        min-width: 486px;
        outline: none;
        padding: 16px;

        &::placeholder {
            line-height: 1.06;
            letter-spacing: normal;
            color: #a9aaa8;
            opacity: 0;
        }
    }

    .searchTerm:focus + .searchLabel,
    &.filled .searchLabel {
        font-size: 12px;
        transform: translate3d(0, -10px, 0);
    }

    &.filled .searchTerm,
    .searchTerm:focus {
        color: #000;
        padding: 18px 16px 0 16px;
    }

    @media only screen and (max-width: 1023px) {
        .searchTerm {
            min-width: 305px;
        }
    }

    @media only screen and (max-width: 700px) {
        width: 61%;

        .searchTerm {
            min-width: 1px;
            width: 100%;
        }
    }

    @media only screen and (max-width: 577px) {
        .searchLabel {
            font-size: 14px;
            left: 10px;
            top: 12px;
        }

        .searchTerm {
            height: 40px;
        }

        .searchTerm:focus + .searchLabel,
        &.filled .searchTerm + .searchLabel {
            display: none;
        }

        &.filled .searchTerm,
        .searchTerm:focus {
            padding: 0 0 0 10px;
        }
    }
}
