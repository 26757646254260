@import "../../../sass/css-variables.scss";

.checkboxGroup{
    .divider {
        width: 100%;
        height: 1px;
        background-color: #c4c4c4;
        margin: 16px 0;
    }

    .contentCheckBox{
        display: flex;
        border:none;
        padding: 0;

        .half{
            flex: 1;
            
            .checkBox{
                margin-bottom: 16px;
            }
     
        }
    }
    input{
        width: 100%;
        height: 38px;
        border: solid 1px #c4c4c4;
        margin-bottom: 16px;
        font-size: 14px;

        padding-left: 16px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    @media screen and (max-width: 576px) {
        .contentCheckBox {
            flex-direction: column;
        }
    }
}