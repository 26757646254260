.InfoProfile {
    &, * { box-sizing: border-box }

    .row {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;

        .col {
            flex: 1;
            padding-right: 16px;
        }
    }

    @media screen and (max-width: 768px) {
        .row {
            flex-wrap: wrap;

            .col {
                min-width: 50%
            }
        }
    }

    @media screen and (max-width: 567px) {
        .row {
            .col {
                min-width: 100%;
            }
        }
    }
}