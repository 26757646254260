.body-header{
    margin-bottom: 18px;
}

.active-coupons{
    padding-bottom:25px;

    .addcoupon{
        padding-bottom:25px;
        .coupon-image{
            flex: 0 0 80%;

            p{
                text-align: left; 
                display: inline-block;
                margin: 0;
                font-family: AutoZoneCond;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: condensed;
                line-height: 1.5;
                letter-spacing: normal;
                color: #231f20;
                display: block;
            }  
             
            img{
                margin-top: 16px;      
            }
        }

        .informationButtonContainer{
            height: auto;                            
            border: none;
            background-color: transparent;            
            padding: 0;            
            flex: 0 0 20%;
                    
        }
    }
    .modalfooter {
        width: 100%;
        padding-top: 16px;
        background-color: #ffffff;
        text-align: right;
        margin-bottom: 48px;

        button:nth-child(1){
            margin-right: 16px;
        }
    }

    .col{
        margin-bottom: 24px;
    }
}
@media screen and (min-width: 768px) {
    .active-coupons .col.last {
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 576px) {
    .addcoupon {
        .no-padding{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}
@media screen and (max-width: 768px) {
    .active-coupons{
        width: auto;
        margin: 0 auto;

        .informationButtonContainer { margin-bottom: 16px !important; }

        .informationButtonContainer, .modalfooter{
            text-align: center;
        }
        img{
            display: block;
            margin: 0 auto;
        }
        .coupon-row{
            img{
                margin: 0;
            }
        }
        .addcoupon{
            margin: 0 auto;
            width: 100%;   
            padding-bottom:25px;
            .info-container{            
                flex-direction: column-reverse;
    
                .coupon-image{
    
                    p{
                        display: block;
                        margin-top: 16px;
                    }
                    img{
                        display: block;
                        margin: 16px auto 0;
                    }
                }
                
            }
            .modalfooter{                
                text-align: center;   
            }

            .no-padding{
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
            .top-padding{
                padding-top: 12px;
            }
            .col{
                margin-top: 0;
            }
        }
    }   
}
