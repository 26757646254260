.results-container{
    
    &, * {box-sizing: border-box;}

    max-width: 1100px;
    width: 100%;
    height: 338px;
    border: solid 1px #e5e5e5;
    background-color: #ffffff;
    margin: 20px 0 64px;
    padding: 0 40px;

    span.icon-noresults{
        display: block;
        width: 96px;
        height: 97px;
        margin: 90px auto 32px;
        background: url(../../../../assets/icon-noresults.JPG) left top no-repeat;
    }

    h4{
        font-family: 'AutoZoneCond-Medium', Arial, sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #787878;
        margin: 0 auto 90px;
        text-transform: uppercase;
        display: block;
    }
    
}

@media screen and (max-width: 768px) {
    .results-container{        
        height: 363px;
        width: 100%;
        

        h4{    
            padding: 0 106px;
        }     
    }
}

@media screen and (max-width: 567px){
    .results-container{
        width: 100%;
        height: 297px;
        span.icon-noresults{
            display: block;
            width: 65px;
            height: 65px;
            margin: 40px auto 32px;
            background: url('../../../../assets/icon-noresults-mobile.JPG') left top no-repeat;
        }
        h4{
            margin: 0 15px 40px;      
            text-align: center;      
            padding: 0 24px;
            font-size: 18px;
        }        
    }
}

