$orange: #fb6121;

.FiltersModal {
    &, * { box-sizing: border-box }

    background-color: #fff;
    box-shadow: 0 0 30px rgba(0,0,0,0.3);
    max-width: 100%;
    width: 914px;

    .content-header {
        background-color: #f5f5f5;
        padding: 16px;

        .clear-button {
            align-items: center;
            background-color: transparent;
            border: none;
            display: flex;
            flex-direction: row;
            margin-left: 60px;
            padding: 0;

            .close-button { transform: scale(0.85) }

            label {
                color: #5b5d5b;
                font-family: "AutoZoneCond-Medium", Arial, sans-serif;
                font-size: 15px;
            }
        }

        .modal-cta {
            background-color: #202124;
            border: none;
            color: #fff;
            font-family: "AutoZoneCond", sans-serif;
            font-weight: normal;
            font-size: 16px;
            height: 40px;
            letter-spacing: 0.75px;
            text-transform: uppercase;
            width: 160px;
        }

        .modal-title {
            color: #202124;
            flex: 1;
            font-family: "AutoZoneCond", sans-serif;
            font-size: 25px;
            margin: 0;
            text-transform: uppercase;

            .orange-line {
                background-color: $orange;
                height: 3px;
                margin-left: 4px;
                margin-top: 8px;
                position: relative;
                width: 80px;

                &:before, &:after {
                    content: '';
                    border-style: solid;
                    border-width: 4px 3px;
                    display: block;
                    position: absolute;
                    transform-origin: center;
                    transform: rotate(90deg);
                }

                &:before {
                    border-color: transparent $orange transparent transparent;
                    left: -3px;
                    top: -4px;
                }

                &:after {
                    border-color: transparent transparent transparent $orange;
                    right: -3px;
                    top: -1px;
                }
            }

            strong {
                font-family: "AutoZoneCond-Heavy", sans-serif;
                font-weight: normal;
            }
        }

        section {
            align-items: center;
            display: flex;
            display: -webkit-flex;

            &:nth-child(2) {
                align-items: flex-start;
                margin-bottom: -8px;
                padding-top: 20px;
            }
        }
    }

    .modal-content {
        padding: 24px 40px;

        hr {
            background-color: #a9aaa8;
            border: none;
            height: 1px;
            margin-bottom: 22px;
        }

        .options-list {
            margin-bottom:24px;

            &:last-child {
                margin-bottom: 0;
            }

            .list-title {
                color: #787878;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 12px;
                font-weight: normal;
                letter-spacing: 1.13px;
                margin: 0 0 17px;
                padding: 0;
                text-transform: uppercase;
            }

            .options {
                column-count: 3;
                list-style: none;
                margin: 0;
                padding: 0;

                .option-item {
                    margin-bottom: 18px;
                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    break-inside: avoid;

                    &:after, &:before {
                        content: '';
                        clear: both;
                    }

                    .text {
                        color: #484848;
                        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                        font-size: 14px;
                        margin-left: 16px;
                    }
                }
            }
        }
    }

    .modal-header {
        align-items: center;
        background-color: #484848;
        display: flex;
        height: 56px;
        justify-content: flex-end;
        padding: 0 16px;
    }

    @media screen and (max-width: 768px) {
        .modal-content {
            padding: 40px 16px;

            .options-list {
                .options {
                    column-count: 2;
                }
            }
        }
    }

    @media screen and (max-width: 567px) {
        .content-header {
            border-bottom: 1px solid #a9aaa8;
            position: relative;

            .clear-button {
                left: 102px;
                position: absolute;
                top: 70px;
            }

            section {
                flex-wrap: wrap;

                .modal-title {
                    font-size: 23px;
                    margin-bottom: 16px;
                    min-width: 100%
                }

                .modal-cta {
                    width: 136px;
                }
            }
        }

        .modal-content {
            padding: 24px 16px;

            hr {
                margin-left: -16px;
                margin-right: 16px;
                width: calc(100% + 32px);
            }

            .options-list {
                margin-bottom: 0;

                .options {
                    column-count: 1;
                }
            }
        }

        .modal-header {
            height: 36px;
            padding: 0 8px;
        }
    }
}