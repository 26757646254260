@import "../../../../../../../sass/css-variables.scss";
@import "../../../../../../../sass/mixins";

.timer {
    @include flex();

    padding: 0 8px;

    span {
        flex: 1;
    }
}
