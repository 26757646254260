@import "../../../../sass/css-variables.scss";

.dropDownElement {
    align-items: center;
    box-sizing: border-box;
    color: $regular-text-color;
    cursor: pointer;
    display: block;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 9px 0 9px 12px;
    text-overflow: ellipsis;
}

.elementSelected {
    font-weight: bold;
    background-color: #e9e9e9;
}
