@import "../../../../sass/css-variables.scss";

@media screen and (max-width: 567px) {
    .gm-style .gm-style-cc span, .gm-style .gm-style-cc a, .gm-style .gm-style-mtc div {
        font-size: 14px !important;
        height: 28px !important;
        padding: 0 10px !important;
    }

    /*
    .gmnoprint {
        div[draggable], button {
            width: 28px !important;
        }

        div[draggable] {
            height: 57px !important;
        }

        button {
            height: 30px !important;

            img {
                height: 14px !important;
                width: 14px !important;
            }

            & + div {
                margin: 0 !important;
                width: 28px !important;
            }
        }
    }
    */
}
/* InfoWindow */
.gm-style-iw.gm-style-iw-c {
    border: 1px solid $autozone-orange-color !important;
}
.gm-style .gm-style-iw-t::after {
    border-left: 1px solid $autozone-orange-color !important;
    border-bottom: 1px solid $autozone-orange-color !important;
}
