@import "../../../sass/css-variables.scss";
@import "../../../sass/global.scss";

.description{
       p{                   
              line-height: 1.4;       
              color: $regular-text-color;       
              margin: 0 16px 24px 0;

              a, a:hover, a:visited, a:link, a:active {
                     color: $autozone-orange-color;
                     cursor: pointer;
                     text-decoration: underline;
              }
       }

       ul > li{
              list-style-image: url('../../../assets/triangle.png');
              line-height: 1.88;
       }

       button{
              margin-bottom: 24px;
       }
        
}
.mapImg{
       background: url(../../../assets/map.jpg) left top no-repeat;
       background-size: contain;
       object-fit: cover;
       width: 100%;
       max-width: 586px;
       height: 473px;   
       margin-bottom: 16px;

       &.es {
              background-image: url(../../../assets/map-es.png);
       }
}


.contact{                                       
       border: solid 1px #e9e9e9;
       background-color: #f8f8f8;
       // margin-left: 3px;
       margin-top: 16px;       
       padding: 16px;                
       box-sizing: border-box;       
       line-height: 21px; 
       max-width: 586px;

       span{

              font-size: 14px;
              
              &:nth-child(1){
                     font-family: 'AutoZoneCond-Medium', Arial, sans-serif;
                     font-weight: normal;
                     color: $regular-text-color;                                
              }

              &.pipe {
                     display: inline-block;
                     padding: 0 6px;
              }

              @media screen and (max-width: 1240px) {
                     &:first-of-type {
                            display: block;
                     }
              }

       }

       .link {
              color: $autozone-orange-color;
              text-decoration: underline;
              cursor: pointer;
              font-size: 14px;
       }
} 

.spinnerContainer {
       display: flex;
       align-items: center;
       justify-content: center;
       margin-top: 155px;
       width: 100%;
}

@media only screen and (max-width: 1024px) {
       .contact{
              width: 488px;
       }
       .mapImg{
              background: url(../../../assets/map-iPad.jpg) left top no-repeat;
              background-size: contain;
              object-fit: cover;
              width: 100%;
              max-width: 488px;
              height: 512px;
              margin: 0 0 21px; 
              
       }
}

@media only screen and (max-width: 488px) {
       .contact{
              width: 288px;
       }
       .mapImg{
              background: url(../../../assets/mapMobile.jpg) left top no-repeat;
              background-size: contain;
              object-fit: cover;
              width: 100%;
              max-width: 290px;
              height: 232px;
              margin-top: 24px;
              margin-bottom: 16px; 
              margin-left: auto;
              margin-right: auto;
       }
}
