.filters-list {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    .filter-item {
        align-items: center;
        background-color: #fff;
        border: 1px solid #5b5d5b;
        border-radius: 5px;
        display: flex;
        height: 24px;
        margin: 0 8px 8px 0;
        padding: 0 2px 0 8px;

        .close-button {
            transform: scale(0.85);
        }

        label {
            color: #5b5d5b;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: bold;
            font-size: 12px;
            margin: 0 2px 0 0;
            padding: 0;
        }
    }
}