@import "../../../sass/css-variables.scss";
$color2: #f4f4f4;
$color1: #f37f00;

.active-coupons{
    padding-bottom: 25px;
    img{
        display: block;
    }
    h4, span.text, .radio-label {
        font-family: 'AutoZoneCond-Medium', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: condensed;
        line-height: 1.13;
        letter-spacing: normal;
        color: #231f20;
        margin: 0 0 17px;
    }

    span.text{
        margin-bottom: 0;
    }

    .col{
        margin-bottom: 24px;
    }

    fieldset {
        margin: 0;        
        margin-top: 8px;    
        border: solid 1px #c4c4c4;
        border-left: none;
        border-right: none;
        border-bottom: none;
        padding: 0;

        legend{
            font-size: 16px !important;
            color: $regular-text-color;            
            padding-right: 5px;
        }

        @media screen and (max-width: 768px) {
            & {
                margin-bottom: 16px;
            }

            legend { margin-bottom: 16px }
        }
             
    }
    .info-container{
        border: solid 1px #c4c4c4;
        background-color: #f8f8f8;
        border-top: none;
        padding: 16px;
        text-align: left;
        vertical-align: middle;

        .informationButtonContainer{
            height: auto;                
            display: flex;
            border: none;
            background-color: transparent;            
            padding: 0;
            //margin-bottom: 16px;

            p{
                text-align: left; 
                display: inline-block;
                margin: 0;
                font-family: AutoZoneCond;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: condensed;
                line-height: 1.5;
                letter-spacing: normal;
                color: #231f20;
                flex: 1;
            }            
        }
        
    }
    .container {
        border: none;
        border-bottom: 1px solid #c4c4c4;
        margin: 0 0 24px 0;
        padding: 0;
        
        .coupon-row{
            margin: 0 0 16px;
        }
                

        .radio {
            margin: 0 0 0.5rem 0;

            input[type="radio"] {
                    position: absolute;
                    opacity: 0;

                    + .radio-label {
                        margin-bottom: 0;

                        &:before {
                            content: '';
                            background: $color2;
                            border-radius: 100%;
                            border: 1px solid darken($color2, 25%);
                            box-sizing: border-box;
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            position: relative;
                            top: 0;
                            margin-right: .5em; 
                            vertical-align: top;
                            cursor: pointer;
                            text-align: center;
                            transition: all 250ms ease;
                        }
                    }
                    &:checked {
                        + .radio-label {
                            &:before {
                                background-color: $color2;
                                box-shadow: inset 0 0 0 4px $color1;
                                border: none;
                            }
                        }
                    }
                    &:focus {
                        + .radio-label {
                            &:before {
                                outline: none;
                                border-color: $color2;
                            }
                        }
                    }
                    &:disabled {
                        + .radio-label {
                            &:before {
                                box-shadow: inset 0 0 0 4px $color1;
                                border-color: darken($color1, 25%);
                                background: darken($color1, 25%);
                            }
                        }
                    }
                    + .radio-label {
                        &:empty {
                            &:before {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        } 
}
@media screen and (max-width: 768px) {
    .active-coupons {
        width: 100%; 
        padding-bottom: 25px;  
        fieldset {
            legend{
                line-height: 1.4;
            }
        }
        .info-container{
            .informationButtonContainer{
            flex-direction: column-reverse;
            margin-bottom:0; 
            button, p{
                margin: 16px auto 0;
            }
            button{
                margin-top: 0;
            }
            }
            img{
                display: block;
                margin: 16px auto 0;
            }
        }
    }
}
