@import "../../../../sass/mixins";
@import "../../../../sass/css-variables.scss";

.editProfile{
    padding: 1em 0;

    .bottom-space {
        margin-bottom: 23px;
    }

    .col {
        @media only screen and (max-width: 768px) {
            &:not(:first-child) {
                margin-top: 22px;
            }

            &:last-of-type {
                /*margin-top: 16px;*/

                &.state {
                    margin-top: 0;
                }
            }
        }
    }

    .InputFileButton {
        margin-right: 16px;
    }

    .row{
        @include flex();
        height: 100%;

        &.center {
            align-items: center;
            -webkit-align-items: center;
        }

        &.end {
            align-items: flex-end;
            -webkit-align-items: flex-end;
        }
    }
}