@import "../../../../sass/mixins";

.InputFileButton {
    @include flex(column);

    align-items: center;
    border: 1px solid;
    box-sizing: border-box;
    cursor: pointer;
    font-family: 'AutoZoneCond-Heavy', sans-serif;
    font-size: 14px;
    height: 28px;
    justify-content: center;
}