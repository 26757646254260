@import "../../../sass/css-variables.scss";
@import "../../../sass/global.scss";

#faqs{
    margin-bottom: 48px;
    h1.faq-header{
        font-family: 'AutoZoneCond-Heavy', sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin: 11px 0 10px 0;
    }
    .box{
        .header{
            span.title{
                font-size: 14px;
            }
        }
        .content{
            padding-top: 3px;
        }
    }
    ul.faq{
        margin: 0;    
        padding-left: 15px;
        list-style: none;
    }
    ul.faq > li{
        list-style-type: none;
        list-style-image: none !important;
        font-size: 14px;
        line-height: normal;
        
    }
    ul.faq > li > a, a:hover, a:visited, a:active{
        color: #f37f00;
        text-decoration: none;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
    }

    ul.faq li:before { 
        content: "";
        border-color: transparent #f37f00;
        border-style: solid;
        border-width: 0.35em 0 0.35em 0.45em;
        display: block;
        height: 0;
        width: 0;
        left: -1em;
        bottom: -0.951em;
        position: relative;
    }

    .answers{
        margin-top: 16px;
        
        p{
            font-size: 14px;
            line-height: 1.57;

            strong{
                display: block;            
            }
            ul{
                margin: 0;
                li{
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 14px;  
                }
            }

            a, a:hover, a:active, a:visited{
                color: #f37f00;     
                font-size: 14px;       
            }
        }
    }
}