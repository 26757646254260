.close-button {
    background-color: transparent;
    border: none;
    display: inline-block;
    height: 24px;
    position: relative;
    width: 24px;

    &:before, &:after {
        background-color: #5b5d5b;
        content: '';
        height: 2px;
        left: 4px;
        position: absolute;
        right: 4px;
        top: calc(50% - 1px);
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }

    &.white:before, &.white:after { background-color: #fff }
}