@import "../../../sass/css-variables";
@import "../../../sass/mixins";

.ModalFooter {
    @include flex();

    align-items: center;
    -webkit-align-items: center;
    background-color: $lightgray-color;
    border-top: 1px solid $gray-color;
    height: 60px;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;

    button {
        margin-left: 16px;

        &:last-child {
            margin-right: 16px;
        }
    }
}