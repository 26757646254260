@import "../../../sass/css-variables.scss";
.datecontainer{
    margin: 0;
    max-width: 100%;

    *{box-sizing: border-box;}

    .wrapper {
        display: flex;
        flex: 1;
    }

    .error-message {
        color: $autozone-red-color;
        display: block;
        font-family: sans-serif;
        font-size: 11px;
        padding-top: 6px;
        margin-bottom: -4px;
    }
    
    .picker-container{
        flex: 1;
        flex-direction: row;
        position: relative;

        &:nth-child(1) {
            flex: 1.1;
        }

        label {
            background-color: white;
            left: 31px;
            position: absolute;
            pointer-events: none;
            top: -7px;
            display: flex;
            max-width: 95%;
            padding: 0 5px 0 5px;            
            z-index: 99;

            span{
                font-family: Arial, sans-serif;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                
    
                &:nth-child(1){
                    color:#d52b1e;
                    padding-right: 3px;
                }
    
                &:nth-child(2){
                    color: #231f20;
                    flex: 1;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
                
        }
        
        .react-datepicker-wrapper{
            width: 100%;

            .react-datepicker__input-container{
                                           
                input[type='text']{  
                    width: 100%;
                    height: 38px;        
                    padding: 8px 0 8px 37px;
                    border: solid 1px #c4c4c4;
                    background: #fff url(../../../assets/icon-cal.JPG) 8px center no-repeat;
                    outline: none;
                    font: normal 14px Arial;                    
                    font-stretch: normal;                    
                    line-height: normal;
                    letter-spacing: normal;
                    color: #3a3c3d;                    
                }               

                input[type='text'].enddate{
                    background: none;
                    padding-left: 13px;
                    margin-left: -1px;
                }
            }
        }
        .react-datepicker-popper[data-placement^="bottom"] {
            margin-top: 0;
        }
        .react-datepicker-popper{
            z-index: 99999;

            .react-datepicker{
                border: none;
                .react-datepicker__navigation--previous{
                    border-right-color: transparent;
                    background-image: url('../../../assets/rightarrow.svg');
                    background-repeat: no-repeat;
                    background-position: top;
                    width: 14px;                    
                }

                .react-datepicker__navigation--next{
                    border-left-color: transparent;
                    background-image: url('../../../assets/leftarrow.svg');
                    background-repeat: no-repeat;
                    background-position: top;
                    width: 14px;     
                }
                
                .react-datepicker__month-container{
                    width: 292px;
                    box-sizing: border-box;
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);

                    .react-datepicker__month{
                        margin: 0 0.8rem 0.8rem;
                    }
                    .react-datepicker__header{
                        background-color: transparent;
                        border-bottom: none;
                        padding-top: 0;

                        .react-datepicker__day-names{
                            margin: 20px 0 0;

                            .react-datepicker__day-name{
                                font: normal 14px Arial;
                                font-stretch: normal;                                
                                line-height: 1.9;
                                letter-spacing: normal;
                                text-align: center;
                                color: #9b9b9b;
                                width: 32px;
                                height: 32px;
                                visibility: hidden;

                                &:nth-child(even),
                                &:nth-child(odd) {
                                    &:after {
                                        visibility: visible;
                                        position: relative;                                            
                                        text-align: center;   
                                        left: -0.55rem;                                         
                                    }
                                } 
                                &:nth-child(1){
                                    &:after{                                        
                                        content: 'S'
                                    }
                                }
                                &:nth-child(2){
                                    &:after{                                       
                                        content: 'M'
                                    }
                                }
                                &:nth-child(3){
                                    &:after{                                       
                                        content: 'T'
                                    }
                                }
                                &:nth-child(4){
                                    &:after{                                       
                                        content: 'W'
                                    }
                                }
                                &:nth-child(5){
                                    &:after{                                       
                                        content: 'T'
                                    }
                                }
                                &:nth-child(6){
                                    &:after{                                       
                                        content: 'F'
                                    }
                                }
                                &:nth-child(7){
                                    &:after{                                       
                                        content: 'S'
                                    }
                                }
                            }
                        }

                        .react-datepicker__current-month{
                            font: normal 16px 'AutoZoneCond-Heavy', sans-serif;                            
                            font-stretch: condensed;                            
                            line-height: normal;
                            letter-spacing: normal;
                            color: #231f20;
                            background-color: #e9e9e9;
                            padding: 10px 0;
                        }
                    }
                    .react-datepicker__month{

                        .react-datepicker__week{

                            .react-datepicker__day:hover{
                                border-radius: 3rem;
                            }
                            .react-datepicker__day{
                                width: 32px;
                                height: 32px;
                                padding: 3px;
                            }
                            .react-datepicker__day--outside-month{
                                color: #c4c4c4;
                            }
                            .react-datepicker__day--selected,
                            .react-datepicker__day--keyboard-selected{
                                border-radius: 3rem;
                                background-color: #f37f00;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    
    :nth-child(2) {
        label{
            left: 5px;
        }
    }

    &.error {
        .picker-container {
            .react-datepicker-wrapper {
                .react-datepicker__input-container {
                    input[type="text"] {
                        border-color: $autozone-red-color;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    &:nth-child(2){
        .react-datepicker-popper{
            left: -150px;
        }
    }
}
