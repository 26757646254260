.BlurPortal {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    bottom: 0;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    left: 0;
    overflow: scroll;
    padding: 63px 0;
    position: fixed;
    right: 0;
    top: 0;

    .backdrop {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;

        & +* {
            z-index: 1;
        }
    }

    .portal-container {
        max-width: 100%;
    }

    @media screen and (max-width: 768px) {
        padding: 0 0 16px 0;
    }

    @media screen and (max-width: 567px) {
        background-color: rgba(255, 255, 255, 1);
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
    }
}
